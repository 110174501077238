<template>
  <NuxtLoadingIndicator />
  <SmartLinkProvider>
    <NuxtLayout :name="layout">
      <NuxtPage />
    </NuxtLayout>
  </SmartLinkProvider>
</template>
<script lang="ts" setup>
const siteStore = useSiteStore()
await siteStore.fetchData()

useHead({
  titleTemplate: '%s - Manatt, Phelps & Phillips, LLP'
})

const layout: any = computed(() => {
  const path = useRoute().fullPath.split('/')[1];

  switch (path) {
    case 'manatt-ventures':
      return 'manatt-ventures'
    case 'custom-elements':
      return false
    default:
      return 'default'
  }
})
</script>
