import type { IContentItemsContainer } from '@kontent-ai/delivery-sdk'
import {
  type ContentBlockquoteModel,
  type ContentTextColorModel,
  type ContentUnderlineTextModel,
  type ScriptTagModel,
  type SiteConfigurationModel
} from '~/models'

interface SiteState {
  siteConfig: SiteConfigurationModel
  fetched: boolean
  linkedItems: IContentItemsContainer
  //pages: KontentPageRoute[]
  collection: string
  textUnderlines: ContentUnderlineTextModel[]
  language: string
  contentQuoteBlocks: ContentBlockquoteModel[]
  textColors: ContentTextColorModel[]
}

export const useSiteStore = defineStore('site', {
  state: (): SiteState => ({
    siteConfig: {} as SiteConfigurationModel,
    fetched: false,
    linkedItems: {} as IContentItemsContainer,
    //pages: [] as KontentPageRoute[],
    textUnderlines: [],
    collection: 'default',
    language: 'en',
    contentQuoteBlocks: [],
    textColors: []
  }),

  actions: {
    setCollection(collection: string) {
      this.collection = collection
    },

    async fetchData() {
      if (!this.fetched) {
        const siteResponse = await $fetch('/api/site-configuration')
        //const pagesResponse = await $fetch('/api/navigation/pages')
        //@ts-ignore
        if (siteResponse.data) {
          //@ts-ignore
          this.siteConfig = siteResponse.data.item as SiteConfigurationModel
          //@ts-ignore
          this.linkedItems = siteResponse.data.linkedItems as IContentItemsContainer

          this.contentQuoteBlocks = siteResponse.data.quotesBlocks
          this.textColors = siteResponse.data.textColors
          this.textUnderlines = siteResponse.data.textUnderlines as ContentUnderlineTextModel[]
        }
        //@ts-ignore
        // if (pagesResponse.data) {
        //   //@ts-ignore
        //   this.pages = pagesResponse.data as KontentPageRoute[]
        // }

        this.fetched = true
      }
    },
    async refreshSiteData() {
      await this.fetchData()
    }
  },

  getters: {
    scriptTags: (state) => {
      const tags: ScriptTagModel[] = []
      if (state.fetched) {
        for (const item of state.siteConfig.elements.scriptTags.value) {
          const linkedItem = state.linkedItems?.[item] as ScriptTagModel
          if (!linkedItem) continue
          tags.push(linkedItem)
        }
      }
      return tags
    },
    getRedirects:
      (state) =>
      async (sourceUrl: string): Promise<string | null> => {
        try {
          const siteResponse = await $fetch('/api/redirects', {
            method: 'GET',
            params: {
              sourceUrl: encodeURIComponent(sourceUrl)
            }
          })
          return siteResponse.data
        } catch (e) {
          console.error(e)
          return null
        }
      },
    getTextUnderlineCode: (state) => (itemId: string) => {
      if (!state.fetched) return ''
      const item = state.textUnderlines.find((x) => x.system.id === itemId)
      return !!item
    },
    getLinkedItem:
      (state) =>
      <T>(codename: string) => {
        return state.linkedItems?.[codename] as T
      },
    getListAs:
      (state) =>
      <T>(codenames: string[]) => {
        if (codenames && codenames.length > 0) {
          return codenames.map((codename) => state.linkedItems?.[codename] as T)
        }
        return []
      },
    getTextColorCode: (state) => (itemId: string) => {
      if (!state.fetched) return ''
      const item = state.textColors.find((x) => x.system.id === itemId)
      if (item) return item.elements.textColor.value[0].codename
      return ''
    },
    getContentQuoteBlock: (state) => (itemId: string) => {
      if (!state.fetched) return false
      const item = state.contentQuoteBlocks.find((x) => x.system.id === itemId)
      return !!item
    }
  }
})
