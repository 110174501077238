import type { IContentItem, IContentItemsContainer } from '@kontent-ai/delivery-sdk'
import type {
  IRefreshMessageData,
  IUpdateMessageData
} from '@kontent-ai/smart-link/types/lib/IFrameCommunicatorTypes'
import { defineStore } from 'pinia'
import forOwn from 'lodash/forOwn'
import get from 'lodash/get'
import set from 'lodash/set'
import keyBy from 'lodash/keyBy'
import forEach from 'lodash/forEach'
import assign from 'lodash/assign'
import values from 'lodash/values'
import filter from 'lodash/filter'
import find from 'lodash/find'
import type { ReferenceModel, TableOfContentLinks } from '~/models'
import type { ApiResponse } from '~/types/ApiResponse'
import type { KontentPageRoute } from '~/types/KontentPageRoute'

interface PageState {
  page: IContentItem | null
  linkedItems: IContentItemsContainer
  nestedReferences: IContentItemsContainer
  pageOnlyreferences: ReferenceModel[],
  pageSuperscriptReferences: string[],
  info: KontentPageRoute | null
}

const getPageReferences = async (
  linkedItems: IContentItemsContainer
): Promise<IContentItemsContainer> => {
  const referenceIds: string[] = []
  forOwn(linkedItems, (value, key) => {
    const elements = get(value, 'elements')
    if (elements) {
      forOwn(elements, (elementValue, _elementKey) => {
        if (get(elementValue, 'type') === 'rich_text') {
          const links = get(elementValue, 'links', [])
          forEach(links, (link: any) => {
            if (link.linkId) {
              referenceIds.push(link.linkId)
            }
          })
        }
      })
    }
  })
  if (referenceIds.length == 0) return {}
  const url = `/api/page/references`
  const { data } = await $fetch<any>(url, {
    method: 'POST',
    body: {
      ids: referenceIds.join(',')
    }
  })
  if (data) {
    const references = data.items.map((c: IContentItem) => assign(c, { isPageReference: true }))
    return keyBy(
      references.map((r: any) =>
        set(
          r,
          'elements.referenceOrderNumber.value',
          get(r, 'elements.referenceOrderNumber.value')!.toString()
        )
      ),
      'elements.referenceOrderNumber.value'
    )
  }
  return {}
}
export const usePageStore = defineStore('page', {
  state: (): PageState => ({
    page: null,
    linkedItems: {} as IContentItemsContainer,
    info: null,
    nestedReferences: {} as IContentItemsContainer,
    pageOnlyreferences: [],
    pageSuperscriptReferences: []
  }),
  actions: {
    async fetchData() {
      this.pageOnlyreferences = []
      const { data } = await useFetch<ApiResponse<any>>(`/api/page/${this.info?.itemid}`)
      if (data) {
        //@ts-ignore
        this.page = data.value.data.item as IContentItem
        //@ts-ignore
        this.linkedItems = data.value.data.linkedItems as IContentItemsContainer

        //@ts-ignore
        const referencesSrc = [this.linkedItems, this.page as IContentItemsContainer]
        //@ts-ignore
        this.nestedReferences = await getPageReferences(referencesSrc)
      }
    },
    async fetchIframeMusicCalculator() {
      const { data } = await useFetch<ApiResponse<any>>(`/api/iframes/music-calculator`)
      if (data) {
        //@ts-ignore
        this.page = data.value.data.item as IContentItem
        //@ts-ignore
        this.linkedItems = data.value.data.linkedItems as IContentItemsContainer
      }
    },
    async addReference(id: string) {
      const url = `/api/page/references`
      const { data } = await $fetch<any>(url, {
        method: 'POST',
        body: {
          ids: id
        }
      })

      if (data) {
        const references = data.items as ReferenceModel[]
        references.forEach((r) => {
          this.pageOnlyreferences[
            r.elements.referenceOrderNumber.value ? r.elements.referenceOrderNumber.value - 1 : 0
          ] = r
        })
      }
    },
    async fetchHome() {
      const response = await $fetch('/api/home')
      if (response) {
        //@ts-ignore
        this.page = response.data.item as WebSpotlightRootModel
        //@ts-ignore
        this.linkedItems = response.data.linkedItems as IContentItemsContainer
      }
    },
    updateItemData(data: IUpdateMessageData) {
      if (!this.page) return
      const targetLinkedItem = this.linkedItems[data.item.codename]

      if (targetLinkedItem) {
        for (let i = 0; i < data.elements.length; i++) {
          const element = data.elements[i]
          const type = element.type
          const newValue = element.data.value
          const propertyName = camelCasePropertyName(element.element.codename)
          if (type === 'text') {
            targetLinkedItem.elements[propertyName].value = newValue
          }
        }
      }
    },
    async refreshItemData(data: IRefreshMessageData) {
      const itemId = this.page?.system.id
      if (itemId) await this.fetchData()
    },
    async getUrlByCodename(codename: string): Promise<string> {
      const { data } = await useFetch<ApiResponse<KontentPageRoute>>(
        `/api/page-info?codename=${codename}`
      )
      if (data && data.value) {
        return data.value.data?.path || ''
      }
      return ''
    },

    async getUrlByItemId(itemId: string) {
      const { data } = await useFetch<ApiResponse<KontentPageRoute>>(
        `/api/page-info?itemid=${itemId}`
      )
      if (data && data.value) {
        return data.value.data?.path
      }
      return ''
    },

    async getPage(urlPath: string, itemId?: string | undefined) {
      if (itemId) {
        const { data } = await useFetch<ApiResponse<KontentPageRoute | null>>(
          `/api/page-info?itemid=${itemId}`
        )
        if (data && data.value) {
          this.info = data.value.data
          return data.value.data
        }
      }
      const { data } = await useFetch<ApiResponse<KontentPageRoute | null>>(
        `/api/page-info?path=${encodeURIComponent(urlPath)}`
      )
      if (data && data.value) {
        this.info = data.value.data
        return data.value.data
      }
    },

    getSuperscriptReference(id: string) {
      return this.pageSuperscriptReferences.find((text: string) => text == id)
    },
    
    addSuperscriptReference(id: string) {
      if (!this.getSuperscriptReference(id)) {
        this.pageSuperscriptReferences.push(id)
      }
    }
  },
  getters: {
    getLinkedItem:
      (state) =>
        <T>(codename: string) => {
          return state.linkedItems?.[codename] as T
        },
    pageTitle: (state) => {
      if (!state.page) return ''
      return state.page.elements.title.value
    },
    pageId: (state) => {
      if (!state.page) return ''
      return state.page.system.id
    },
    pageInfo: (state) => {
      return state.info
    },
    getListAs:
      (state) =>
        <T>(codenames: string[]) => {
          if (codenames && codenames.length > 0) {
            return codenames.map((codename) => state.linkedItems?.[codename] as T)
          }
          return []
        },
    getTableOfContents: (state) => (): TableOfContentLinks[] => {
      return filter(values(state.linkedItems), (item) =>
        find(
          get(item.elements, 'tableOfContentLinksTableOfContents.value', []),
          (option) => get(option, 'codename') === 'show'
        )
      ) as TableOfContentLinks[]
    },
    getThemeColor: (state) => (): string => {
      return get(state, 'page.elements.color.value[0].codename', 'black')
    },
    getPageReferences: (state) => () => {
      return state.nestedReferences
    }
  }
})
