<template>
  <slot></slot>
</template>
<script setup lang="ts">
const config = useRuntimeConfig()
const pageStore = usePageStore()
const siteStore = useSiteStore()
if (config.public.enableSmartLinks) {
  useHead(() => {
    return {
      bodyAttrs: {
        'data-kontent-project-id': config.public.projectId,
        'data-kontent-language-codename': 'default'
      }
    }
  })

  if (import.meta.client) {
    useSmartLinkProvider()
    onMounted(() => {
      useCustomRefresh(async (data, metadata, originalRefresh) => {
        // Your refresh logic
        await pageStore.refreshItemData(data)
        await siteStore.refreshSiteData()
      })

      useLivePreview((data) => {
        // Your live preview logic
        pageStore.updateItemData(data)
      })
    })
  }
}
</script>
