import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/working_dir/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/working_dir/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/working_dir/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/working_dir/src/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/working_dir/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/working_dir/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/working_dir/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/working_dir/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/working_dir/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/working_dir/src/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/working_dir/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk from "/working_dir/src/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import bootstrap_client_c_fMhHoCA_KTiu88qzHpLQCaAGpNyyqa3D_a3ozzERI from "/working_dir/src/plugins/bootstrap.client.ts";
import footer_0McdKkhaf66UpgjSZEjmW0WFbCnUWNOa_RUHnZXbn9A from "/working_dir/src/plugins/footer.ts";
import header_vtzN5EzShFoGC7RLAGq2pkIRMDZbYhEzcy_kO8Em2wU from "/working_dir/src/plugins/header.ts";
import recaptcha_client_n2dsqP3RF3ekw_Fa_WpZIp9Am1Ho_nkycV3N0WIrpUk from "/working_dir/src/plugins/recaptcha.client.ts";
import script_tags_client_cYtm5cjTGGK_C2PYG0OGmrd42XpTdjCXgojrpOXFK0g from "/working_dir/src/plugins/script-tags.client.ts";
import toast_client_aJM71VRKDdVxlg_WSmA3MY8SSpRRbbEgefE9DP_VLH0 from "/working_dir/src/plugins/toast.client.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk,
  bootstrap_client_c_fMhHoCA_KTiu88qzHpLQCaAGpNyyqa3D_a3ozzERI,
  footer_0McdKkhaf66UpgjSZEjmW0WFbCnUWNOa_RUHnZXbn9A,
  header_vtzN5EzShFoGC7RLAGq2pkIRMDZbYhEzcy_kO8Em2wU,
  recaptcha_client_n2dsqP3RF3ekw_Fa_WpZIp9Am1Ho_nkycV3N0WIrpUk,
  script_tags_client_cYtm5cjTGGK_C2PYG0OGmrd42XpTdjCXgojrpOXFK0g,
  toast_client_aJM71VRKDdVxlg_WSmA3MY8SSpRRbbEgefE9DP_VLH0
]