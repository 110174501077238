import { ApiResponse } from '~/types/ApiResponse.ts'
import type { NavigationItem } from '~/types/NavigationItem.ts'

export default defineNuxtPlugin(async (nuxtApp) => {
  const { data } = await useAsyncData('footer', () =>
    $fetch<ApiResponse<NavigationItem[]>>('/api/navigation/footer-menu')
  )

  nuxtApp.provide('footer', data.value)
})
