import { contentTypes } from '~/models'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const path = to.path
  const siteStore = useSiteStore()
  const pageStore = usePageStore()
  await siteStore.fetchData()
  const url = useRequestURL()
  const sourceUrlParts = url.href.replace('https://', '').replace('http://', '').split('/')

  let sourceUrl = ''
  for (let i = 1; i < sourceUrlParts.length; i++) {
    sourceUrl = `${sourceUrl}/${sourceUrlParts[i]}`
  }

  const targetPath = await siteStore.getRedirects(sourceUrl)
  if (targetPath) {
    return navigateTo(targetPath, {
      external: true,
      redirectCode: 302
    })
  }

  const page = await pageStore.getPage(path, to.query.itemid as string)
  if (page) {
    if (page.redirectTo && page.redirectTo.length > 0) {
      return navigateTo(page.redirectTo, {
        external: page.redirectTo.startsWith('http'),
        redirectCode: 302
      })
    } else if (page.type === contentTypes.folder.codename) {
      return abortNavigation()
    }
  }
})
