<script lang="ts" setup>
const error = useError()

const handleError = () => clearError({ redirect: '/' })

const errorMessage = computed(() => {
  if (error.value?.statusCode === 404) {
    return 'Page not found'
  }
  return 'Oops! Something went wrong.'
})
</script>

<template>
  <Title>Error - {{ error?.statusCode }}</Title>
  <NuxtLayout>
    <div class="not-found">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="fourofour">
              <div class="number">{{ error?.statusCode }}</div>

              <div class="notfound">{{ errorMessage }}</div>
              <p v-if="error?.statusCode === 404"
                >Something is wrong. The page you are looking for is no longer here or it was
                moved.</p
              >
              <pre v-if="!useRuntimeConfig().public.isProduction">
                {{ error }}
              </pre>
              <p
                >Please click
                <button class="btn-link" @click="handleError">here</button>
                to go back to the Homepage.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<style lang="scss" scoped>
.fourofour {
  margin: 5em 0;

  .number {
    font-size: 12em;
    line-height: 1;
  }

  .notfound {
    font-size: 32px;
  }
}
</style>
