
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as demoIEX1B_SMH4gsEU2VLVIhQji4wlQ_A0FTPoLaXVSai4QMeta } from "/working_dir/src/pages/demo.vue?macro=true";
import { default as indexaxAPAHMTeJ5L4g2tT9tRdwkrj2aXP8AM1xhTY_45Oah_4Meta } from "/working_dir/src/pages/index.vue?macro=true";
import { default as searchs48RtXrKddFYr3B4RbP5OveKvHxYVw7ismacAEok3yUMeta } from "/working_dir/src/pages/search.vue?macro=true";
import { default as _91_46_46_46route_45path_93BOhRK2YXB9ObYBh4iTs9mLq6401fp_45XGxYRrE76Lq1IMeta } from "/working_dir/src/pages/[...route-path].vue?macro=true";
import { default as page_45urliiCL_4549fR8PcpYB4ETUlt6VZ4CAOlCDBBRXIxnjujTMMeta } from "/working_dir/src/pages/custom-elements/page-url.vue?macro=true";
import { default as toast_45ui_45interpreteryHxv5Nh4_45BuZ8avRLLxgKdc3VIZgqFeIh3z_sM1PwEIMeta } from "/working_dir/src/pages/custom-elements/toast-ui-interpreter.vue?macro=true";
import { default as _91slug_93YTGLzBtoggx3WM1bSLZ5yA5PX1FQTVu_455942XWFVq7MMeta } from "/working_dir/src/pages/manatt-ventures/about/our-team/[slug].vue?macro=true";
import { default as u_45s_45music_45streaming_45royalty_45calculatorIAvbRpFNNtNpU9YqC8NGTSbeucK6sbN0_45mDMTKo09GgMeta } from "/working_dir/src/pages/iframes/u-s-music-streaming-royalty-calculator.vue?macro=true";
export default [
  {
    name: "demo",
    path: "/demo",
    component: () => import("/working_dir/src/pages/demo.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/working_dir/src/pages/index.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/working_dir/src/pages/search.vue")
  },
  {
    name: "routepath",
    path: "/:routepath(.*)*",
    component: () => import("/working_dir/src/pages/[...route-path].vue")
  },
  {
    name: "custom-elements-page-url",
    path: "/custom-elements/page-url",
    meta: page_45urliiCL_4549fR8PcpYB4ETUlt6VZ4CAOlCDBBRXIxnjujTMMeta || {},
    component: () => import("/working_dir/src/pages/custom-elements/page-url.vue")
  },
  {
    name: "custom-elements-toast-ui-interpreter",
    path: "/custom-elements/toast-ui-interpreter",
    meta: toast_45ui_45interpreteryHxv5Nh4_45BuZ8avRLLxgKdc3VIZgqFeIh3z_sM1PwEIMeta || {},
    component: () => import("/working_dir/src/pages/custom-elements/toast-ui-interpreter.vue")
  },
  {
    name: "manatt-ventures-about-our-team-slug",
    path: "/manatt-ventures/about/our-team/:slug()",
    component: () => import("/working_dir/src/pages/manatt-ventures/about/our-team/[slug].vue")
  },
  {
    name: "iframes-u-s-music-streaming-royalty-calculator",
    path: "/iframes/u-s-music-streaming-royalty-calculator",
    meta: u_45s_45music_45streaming_45royalty_45calculatorIAvbRpFNNtNpU9YqC8NGTSbeucK6sbN0_45mDMTKo09GgMeta || {},
    component: () => import("/working_dir/src/pages/iframes/u-s-music-streaming-royalty-calculator.vue")
  }
]