/**
 * Generated by '@kontent-ai/model-generator@7.4.0'
 */
export const contentTypes = {
    /**
     *    Global Footer
     */
    _global_footer: {
        codename: '_global_footer',
        id: 'eea5c143-7702-4e7e-8a51-a16f189b8cb7',
        externalId: undefined,
        name: '🌍 Global Footer',
        elements: {
            /**
             * Company Name (text)
             *
             * The year is dynamic. Do not add to the text.
             */
            company_name: {
                codename: 'company_name',
                id: 'fc0b4388-3cc1-4482-9de9-07f9608d86af',
                externalId: undefined,
                name: 'Company Name',
                required: false,
                type: 'text'
            },

            /**
             * Facebook Link (text)
             */
            facebook_link: {
                codename: 'facebook_link',
                id: '448ff800-9f9e-4243-a156-1a61c75bdafc',
                externalId: undefined,
                name: 'Facebook Link',
                required: false,
                type: 'text'
            },

            /**
             * Legal Pages (modular_content)
             */
            legal_pages: {
                codename: 'legal_pages',
                id: 'd6458b5c-f130-4b2a-bdb5-d63aba3fb0d0',
                externalId: undefined,
                name: 'Legal Pages',
                required: false,
                type: 'modular_content'
            },

            /**
             * Legal Text (text)
             */
            legal_text: {
                codename: 'legal_text',
                id: 'b1086548-b4af-4118-ad93-565f5e71c1b5',
                externalId: undefined,
                name: 'Legal Text',
                required: false,
                type: 'text'
            },

            /**
             * LinkedIn Link (text)
             */
            linkedin_link: {
                codename: 'linkedin_link',
                id: '97cac194-f758-49d9-95d6-72e40e91d89a',
                externalId: undefined,
                name: 'LinkedIn Link',
                required: false,
                type: 'text'
            },

            /**
             * Logo (asset)
             */
            logo: {
                codename: 'logo',
                id: '5075188d-0c4f-41cf-802d-37d00b1e11dd',
                externalId: undefined,
                name: 'Logo',
                required: false,
                type: 'asset'
            },

            /**
             * Navigation Sections (modular_content)
             */
            navigation_items: {
                codename: 'navigation_items',
                id: 'cd738915-9f70-4399-b59a-27773dbcb65e',
                externalId: undefined,
                name: 'Navigation Sections',
                required: false,
                type: 'modular_content'
            },

            /**
             * Newsletter Text (text)
             */
            newsletter_text: {
                codename: 'newsletter_text',
                id: '3b68b2df-2b0d-4fcf-8472-64f19f33ab9c',
                externalId: undefined,
                name: 'Newsletter Text',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Navigation Item
     */
    navigation_item: {
        codename: 'navigation_item',
        id: 'bb59939d-7035-42d3-9fbb-92ae4ab3b89d',
        externalId: undefined,
        name: '🌍 Navigation Item',
        elements: {
            /**
             * External URL (text)
             */
            external_url: {
                codename: 'external_url',
                id: '14744de6-776f-488f-82df-8e1e39229a6f',
                externalId: undefined,
                name: 'External URL',
                required: false,
                type: 'text'
            },

            /**
             * Page (modular_content)
             */
            page: {
                codename: 'page',
                id: '75603161-db3c-4892-ad02-f6797e195d9f',
                externalId: undefined,
                name: 'Page',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '098b8c58-64d9-4665-ba55-cc617379be8a',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Navigation Section
     */
    navigation_section: {
        codename: 'navigation_section',
        id: 'c38a6794-5dec-4930-9cf1-d5e9baaf0d83',
        externalId: undefined,
        name: '🌍 Navigation Section',
        elements: {
            /**
             * Navigation Items (modular_content)
             */
            navigation_items: {
                codename: 'navigation_items',
                id: '53648bd5-a5c9-4ed0-8806-6b51202d55cd',
                externalId: undefined,
                name: 'Navigation Items',
                required: false,
                type: 'modular_content'
            },

            /**
             * Section Landing Page (modular_content)
             */
            section_landing_page: {
                codename: 'section_landing_page',
                id: 'd7299f8e-0446-4121-a4d9-bfcd9f003acd',
                externalId: undefined,
                name: 'Section Landing Page',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '699c05a4-8bc9-487d-af64-8c3a839448a8',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Pardot Subscriptions
     */
    _pardot_subscriptions: {
        codename: '_pardot_subscriptions',
        id: 'cf338a28-d38e-4958-bfbc-7221945f0b79',
        externalId: undefined,
        name: '🌍 Pardot Subscriptions',
        elements: {
            /**
             * Pardot URL (text)
             */
            pardot_url: {
                codename: 'pardot_url',
                id: 'f4c41ed5-088a-4708-99bf-9b6c93d9a298',
                externalId: undefined,
                name: 'Pardot URL',
                required: false,
                type: 'text'
            },

            /**
             * Subscriptions (modular_content)
             */
            subscriptions: {
                codename: 'subscriptions',
                id: 'ae555a76-8f92-4b9e-b3c2-007f413a1f01',
                externalId: undefined,
                name: 'Subscriptions',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     *    Redirect Rule
     */
    _redirect_rule: {
        codename: '_redirect_rule',
        id: '14a6605e-ef21-44aa-b598-b374abe534b3',
        externalId: undefined,
        name: '🌍 Redirect Rule',
        elements: {
            /**
             * Source Path (text)
             *
             * Path should start with "/" ex. "/faq",
             */
            source_path: {
                codename: 'source_path',
                id: '025bfafa-7d3b-489f-a501-7bc5d466bad1',
                externalId: undefined,
                name: 'Source Path',
                required: false,
                type: 'text'
            },

            /**
             * Target Path or URL (text)
             *
             * Path should start with "/", a URL should start with "http".
             */
            target_path_or_url: {
                codename: 'target_path_or_url',
                id: '5a00ecca-b4a1-482b-9f04-b4118503f8a4',
                externalId: undefined,
                name: 'Target Path or URL',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Script Tag
     */
    script_tag: {
        codename: 'script_tag',
        id: 'bed6292c-8044-4274-a539-596500e2a4a5',
        externalId: undefined,
        name: '🌍 Script Tag',
        elements: {
            /**
             * Inner Code (text)
             *
             * This will not preserve the attributes, just the code inside the <script> tag.
             */
            inner_code: {
                codename: 'inner_code',
                id: '4d84ccf9-d954-425a-aa0a-e728764fc64f',
                externalId: undefined,
                name: 'Inner Code',
                required: false,
                type: 'text'
            },

            /**
             * Location (multiple_choice)
             */
            location: {
                codename: 'location',
                id: '40139063-84dc-4d0a-99b7-0421ce588f5e',
                externalId: undefined,
                name: 'Location',
                required: true,
                type: 'multiple_choice',
                options: {
                    head: {
                        name: 'Head',
                        id: '60d6b7e4-b145-4354-ab13-6d5a2d6c7bd7',
                        codename: 'head',
                        externalId: undefined
                    },
                    body_open: {
                        name: 'Body Open',
                        id: 'c07e77ff-f1a7-4931-b65f-3d9b913623d4',
                        codename: 'body_open',
                        externalId: undefined
                    },
                    body_close: {
                        name: 'Body Close',
                        id: '455af788-75a9-4fca-b5bd-d655fae7534e',
                        codename: 'body_close',
                        externalId: undefined
                    }
                }
            },

            /**
             * URL (text)
             */
            url: {
                codename: 'url',
                id: 'af901cd7-164f-46ee-85b9-2864def0fa80',
                externalId: undefined,
                name: 'URL',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Site Configuration
     */
    site_configuration: {
        codename: 'site_configuration',
        id: '2437ef04-9ffe-4b3e-8f38-1caa526fbe90',
        externalId: undefined,
        name: '🌍 Site Configuration',
        elements: {
            /**
             * Favicon (asset)
             */
            favicon: {
                codename: 'favicon',
                id: '5a50476c-7580-42e2-976f-3900250f5607',
                externalId: undefined,
                name: 'Favicon',
                required: false,
                type: 'asset'
            },

            /**
             * Footer Logo (asset)
             */
            footer_logo: {
                codename: 'footer_logo',
                id: 'b650f05f-54d1-4285-8df7-1a638531eed0',
                externalId: undefined,
                name: 'Footer Logo',
                required: false,
                type: 'asset'
            },

            /**
             * Meta Keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '0ca5c9ec-b34f-48df-933b-792568906e4c',
                externalId: undefined,
                name: 'Meta Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Navigation Footer (modular_content)
             */
            navigation_footer: {
                codename: 'navigation_footer',
                id: 'e8c244e3-f66b-4a93-a4da-ee869f869828',
                externalId: undefined,
                name: 'Navigation Footer',
                required: false,
                type: 'modular_content'
            },

            /**
             * Navigation Header (modular_content)
             */
            navigation_header: {
                codename: 'navigation_header',
                id: '04b21e23-d6bc-40f0-ac48-fd2b1c9e0d75',
                externalId: undefined,
                name: 'Navigation Header',
                required: false,
                type: 'modular_content'
            },

            /**
             * Pardot Subscriptions (modular_content)
             */
            pardot_subscriptions: {
                codename: 'pardot_subscriptions',
                id: 'e282f456-91df-4210-a44d-193a1254a877',
                externalId: undefined,
                name: 'Pardot Subscriptions',
                required: false,
                type: 'modular_content'
            },

            /**
             * Script Tags (modular_content)
             */
            script_tags: {
                codename: 'script_tags',
                id: '91f729ca-898a-4025-9526-516da7cfe674',
                externalId: undefined,
                name: 'Script Tags',
                required: false,
                type: 'modular_content'
            },

            /**
             * Site Name (text)
             */
            site_name: {
                codename: 'site_name',
                id: 'bd9222ea-3352-4334-9052-a29bc566ece0',
                externalId: undefined,
                name: 'Site Name',
                required: false,
                type: 'text'
            },

            /**
             * Twitter Creator (text)
             */
            twitter_creator: {
                codename: 'twitter_creator',
                id: '6c5726dc-f650-459e-ad8c-052a7617f500',
                externalId: undefined,
                name: 'Twitter Creator',
                required: false,
                type: 'text'
            },

            /**
             * Website Logo (asset)
             */
            website_logo: {
                codename: 'website_logo',
                id: '26324c7f-01fc-4ade-acfe-025b1c8ae3d0',
                externalId: undefined,
                name: 'Website Logo',
                required: false,
                type: 'asset'
            }
        }
    },

    /**
     *    Folder
     */
    folder: {
        codename: 'folder',
        id: '09f4fa01-27a9-4ad1-85bf-5514be124b34',
        externalId: undefined,
        name: '📁 Folder',
        elements: {
            /**
             * Subpages (subpages)
             */
            subpages: {
                codename: 'subpages',
                id: 'f090f73d-ea94-4aa7-940a-2822768de5e4',
                externalId: undefined,
                name: 'Subpages',
                required: false,
                type: 'subpages'
            }
        }
    },

    /**
     *    Capabilities Page
     */
    capabilities_page: {
        codename: 'capabilities_page',
        id: '7c257754-bf7e-43b4-b329-de17bd02cb1d',
        externalId: undefined,
        name: '📄 Capabilities Page',
        elements: {
            /**
             * Break Component (rich_text)
             *
             * The Break Component spans the width of the page.  Use this for a 🧩Split Content Block housing a 🧩Capability List & 🧩 Client list or a different content model that you want to display as full width.
             */
            break_component: {
                codename: 'break_component',
                id: '4002a70a-b49c-42d7-ab88-99d520d0f881',
                externalId: undefined,
                name: 'Break Component',
                required: false,
                type: 'rich_text'
            },

            /**
             * Capability (taxonomy)
             */
            capability: {
                codename: 'capability',
                id: 'da8bfddf-fbd1-4d2e-bca3-0b6059eec8c6',
                externalId: undefined,
                name: 'Capability',
                required: true,
                type: 'taxonomy'
            },

            /**
             * Color (multiple_choice)
             */
            color: {
                codename: 'color',
                id: '61430eba-4c37-431b-ad82-758be01b5f92',
                externalId: undefined,
                name: 'Color',
                required: true,
                type: 'multiple_choice',
                options: {
                    blue: {
                        name: 'Blue',
                        id: 'f43656e9-a43f-4fe9-94e5-10faf925e48a',
                        codename: 'blue',
                        externalId: undefined
                    },
                    gold: {
                        name: 'Gold',
                        id: '8f944345-bdc7-4bb7-a5b4-9bf76714051f',
                        codename: 'gold',
                        externalId: undefined
                    },
                    green: {
                        name: 'Green',
                        id: 'ec56331c-70fd-4a91-ad8c-d7180efc611e',
                        codename: 'green',
                        externalId: undefined
                    },
                    magenta: {
                        name: 'Magenta',
                        id: 'c6fc8c32-fcee-4f89-a67e-6e58b3989804',
                        codename: 'magenta',
                        externalId: undefined
                    },
                    orange: {
                        name: 'Orange',
                        id: 'c4d8856f-e8cd-436e-ab19-5b48f22198d3',
                        codename: 'orange',
                        externalId: undefined
                    },
                    red: {
                        name: 'Red',
                        id: '366dc25b-dfe7-4ed7-a851-28bcfa388895',
                        codename: 'red',
                        externalId: undefined
                    }
                }
            },

            /**
             * Content (rich_text)
             *
             * Content 🧩puzzle pieces with custom layouts/designs such as an 🧩 Insights list, 🧩Content Block for Experience List, 🧩 Awards List, 🧩 Team List etc. go here.
             */
            content: {
                codename: 'content',
                id: '5392f417-3ba4-4ff3-ad9a-83d554615eb3',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Hero (modular_content)
             */
            hero: {
                codename: 'hero',
                id: 'd78e2814-254d-43cc-a75e-d134350a7611',
                externalId: undefined,
                name: 'Hero',
                required: true,
                type: 'modular_content'
            },

            /**
             * Leader (modular_content)
             *
             * This element links to a 📄 People Page and will display a summary of their contact information in a card rendered on this page.
             */
            leader: {
                codename: 'leader',
                id: '1f9907a9-106b-498d-847a-775dc80c46d8',
                externalId: undefined,
                name: 'Leader',
                required: false,
                type: 'modular_content'
            },

            /**
             * Meta Keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '0ca5c9ec-b34f-48df-933b-792568906e4c',
                externalId: undefined,
                name: 'Meta Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Nav Link Description (rich_text)
             *
             * This text will appear for a Capability on hover when the Capability is used in the Services/Industries list.  You can reuse the short description content here if you'd like.
             */
            nav_link_description: {
                codename: 'nav_link_description',
                id: 'c2872cc0-7275-48d7-93e0-b378630f3819',
                externalId: undefined,
                name: 'Nav Link Description',
                required: false,
                type: 'rich_text'
            },

            /**
             * Overview Text (rich_text)
             *
             * This section houses the page's text content, which will be truncated by the Read More item below if a character count is defined.
             */
            overview_text: {
                codename: 'overview_text',
                id: '721b2ee2-8ab5-48fa-93c8-762f2e425ce3',
                externalId: undefined,
                name: 'Overview Text',
                required: true,
                type: 'rich_text'
            },

            /**
             * Overview Title (text)
             */
            overview_title: {
                codename: 'overview_title',
                id: '5733bffe-4533-4940-b191-63b339f0b63a',
                externalId: undefined,
                name: 'Overview Title',
                required: false,
                type: 'text'
            },

            /**
             * Page URL (custom)
             */
            urls__page_url: {
                codename: 'urls__page_url',
                id: '8d8e9c64-809c-4fb2-af2d-a62f2a368cfc',
                externalId: undefined,
                name: 'Page URL',
                required: false,
                type: 'custom',

                snippetCodename: 'urls'
            },

            /**
             * Read More Character Count (number)
             *
             * Controls the amount of text content shown before a Read More item appears.  Character count includes spaces and punctuation.  You can check the character count for a body of content via a word processor app such as MS Word, Google Docs, etc. to curate this.
             */
            read_more_character_count: {
                codename: 'read_more_character_count',
                id: '94a13bc5-b5b8-4f67-941e-88f3a43eb915',
                externalId: undefined,
                name: 'Read More Character Count',
                required: false,
                type: 'number'
            },

            /**
             * Short Description (text)
             */
            short_description: {
                codename: 'short_description',
                id: 'b5398051-161e-4ae6-9654-5e31e9ded8f5',
                externalId: undefined,
                name: 'Short Description',
                required: false,
                type: 'text'
            },

            /**
             * Subpages (subpages)
             */
            subpages: {
                codename: 'subpages',
                id: '77bde802-3d04-4395-8772-5c3639aad3f2',
                externalId: undefined,
                name: 'Subpages',
                required: false,
                type: 'subpages'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '2d1bc4a7-7135-4d1b-bd91-75518fab711f',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: '9eef716d-2ed8-4a7d-b183-6057a9e2b23a',
                externalId: undefined,
                name: 'URL Slug',
                required: true,
                type: 'url_slug'
            }
        }
    },

    /**
     *    Career Opportunities Page
     */
    career_opportunities_page: {
        codename: 'career_opportunities_page',
        id: '395b57cf-b1e0-479b-8d0c-f283a19feaf9',
        externalId: undefined,
        name: '📄 Career Opportunities Page',
        elements: {
            /**
             * Call to Action (modular_content)
             */
            call_to_action: {
                codename: 'call_to_action',
                id: '6532e093-3260-4474-84f4-c881f24f5ab2',
                externalId: undefined,
                name: 'Call to Action',
                required: false,
                type: 'modular_content'
            },

            /**
             * Career Category (taxonomy)
             */
            career_category: {
                codename: 'career_category',
                id: '0cb90810-1a9d-4849-8567-c2323dfc5ab1',
                externalId: undefined,
                name: 'Career Category',
                required: false,
                type: 'taxonomy'
            },

            /**
             * Color (multiple_choice)
             */
            color: {
                codename: 'color',
                id: '83367be9-2dbd-417f-904c-ad0486c65556',
                externalId: undefined,
                name: 'Color',
                required: false,
                type: 'multiple_choice',
                options: {
                    blue: {
                        name: 'Blue',
                        id: '04717763-37b8-46c1-9919-30a2a4f52306',
                        codename: 'blue',
                        externalId: undefined
                    },
                    gold: {
                        name: 'Gold',
                        id: '7a11bd44-eddb-43ff-a50f-daf33ffc92a1',
                        codename: 'gold',
                        externalId: undefined
                    },
                    green: {
                        name: 'Green',
                        id: 'af5b112f-d8e2-449f-a896-ac8106d21385',
                        codename: 'green',
                        externalId: undefined
                    },
                    magenta: {
                        name: 'Magenta',
                        id: '4ac5a527-1ac5-4757-9bb1-4b393f95ff57',
                        codename: 'magenta',
                        externalId: undefined
                    },
                    orange: {
                        name: 'Orange',
                        id: '248d7ca6-acba-4f0f-8eab-634c77874725',
                        codename: 'orange',
                        externalId: undefined
                    },
                    red: {
                        name: 'Red',
                        id: '1df585b8-74b9-4038-bb3c-08eb468e6baf',
                        codename: 'red',
                        externalId: undefined
                    }
                }
            },

            /**
             * Contact (modular_content)
             */
            contact: {
                codename: 'contact',
                id: '93ba948a-5405-4c6a-9a9b-e97b871c1986',
                externalId: undefined,
                name: 'Contact',
                required: false,
                type: 'modular_content'
            },

            /**
             * Content (custom)
             */
            content: {
                codename: 'content',
                id: 'db25b90b-8c28-4b23-92eb-c54f5bd85811',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'custom'
            },

            /**
             * Department (taxonomy)
             */
            department: {
                codename: 'department',
                id: 'a34bc966-5b37-4b8c-982c-e147a0bb2f62',
                externalId: undefined,
                name: 'Department',
                required: false,
                type: 'taxonomy'
            },

            /**
             * Featured Position (multiple_choice)
             */
            featured_position: {
                codename: 'featured_position',
                id: 'b9588d4d-303f-4837-b168-58da62c516e9',
                externalId: undefined,
                name: 'Featured Position',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '8333ca4d-cea2-4dcf-ac2d-feda76b725f3',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '95685a2d-5f5f-4182-ae36-857d0a96dde7',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            },

            /**
             * Job Number (number)
             */
            job_number: {
                codename: 'job_number',
                id: '4c87129f-15bf-45de-ba59-f942760d3a5d',
                externalId: undefined,
                name: 'Job Number',
                required: false,
                type: 'number'
            },

            /**
             * Location (modular_content)
             */
            location: {
                codename: 'location',
                id: '7145336b-4402-4bac-b1e0-d1dfba36705c',
                externalId: undefined,
                name: 'Location',
                required: false,
                type: 'modular_content'
            },

            /**
             * Meta Keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '0ca5c9ec-b34f-48df-933b-792568906e4c',
                externalId: undefined,
                name: 'Meta Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Page URL (custom)
             */
            urls__page_url: {
                codename: 'urls__page_url',
                id: '8d8e9c64-809c-4fb2-af2d-a62f2a368cfc',
                externalId: undefined,
                name: 'Page URL',
                required: false,
                type: 'custom',

                snippetCodename: 'urls'
            },

            /**
             * Short Description (custom)
             */
            short_description: {
                codename: 'short_description',
                id: '72835b10-9494-483c-bac8-5d7a1cabcbff',
                externalId: undefined,
                name: 'Short Description',
                required: false,
                type: 'custom'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '19947735-5aa5-4434-8d36-934ccf23102d',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: '7e0abe11-76c5-4cb9-86eb-03742d2cccc6',
                externalId: undefined,
                name: 'URL Slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *    Homepage
     */
    homepage: {
        codename: 'homepage',
        id: 'ef636bfe-6c96-4ff5-80a4-7f5b57a89622',
        externalId: undefined,
        name: '📄 Homepage',
        elements: {
            /**
             * Banner CTA (modular_content)
             */
            banner_cta: {
                codename: 'banner_cta',
                id: '6ea67183-38bd-43db-96fa-fb59012d7d81',
                externalId: undefined,
                name: 'Banner CTA',
                required: false,
                type: 'modular_content'
            },

            /**
             * Featured Content (modular_content)
             */
            featured_content: {
                codename: 'featured_content',
                id: '1e5316e7-646f-4bb1-9d1b-80498deb99da',
                externalId: undefined,
                name: 'Featured Content',
                required: false,
                type: 'modular_content'
            },

            /**
             * Hero (modular_content)
             */
            hero: {
                codename: 'hero',
                id: 'a7a45cb7-b442-4c19-915d-29aee296ceda',
                externalId: undefined,
                name: 'Hero',
                required: false,
                type: 'modular_content'
            },

            /**
             * Meta Keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '0ca5c9ec-b34f-48df-933b-792568906e4c',
                externalId: undefined,
                name: 'Meta Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Page URL (custom)
             */
            urls__page_url: {
                codename: 'urls__page_url',
                id: '8d8e9c64-809c-4fb2-af2d-a62f2a368cfc',
                externalId: undefined,
                name: 'Page URL',
                required: false,
                type: 'custom',

                snippetCodename: 'urls'
            },

            /**
             * People Search (modular_content)
             */
            people_search: {
                codename: 'people_search',
                id: '7d82272f-6924-4645-8744-f4cbac88245f',
                externalId: undefined,
                name: 'People Search',
                required: false,
                type: 'modular_content'
            },

            /**
             * Subpages (subpages)
             */
            subpages: {
                codename: 'subpages',
                id: '501fe00a-7c57-4876-9b42-a916c3ac67d1',
                externalId: undefined,
                name: 'Subpages',
                required: false,
                type: 'subpages'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '7908dc22-06f1-42db-9272-02a8a8c31b45',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: '110205d0-115a-4242-aa15-577c95c51012',
                externalId: undefined,
                name: 'URL Slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *    iFrame Page
     */
    _iframe_page: {
        codename: '_iframe_page',
        id: '77cc1762-6bea-4059-9bb7-7eec616af82a',
        externalId: undefined,
        name: '📄 iFrame Page',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '8e4a1b72-eb41-4d56-a50e-a9196b304361',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '09f23206-9b6b-46e5-bb72-f61a9b8f9176',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: 'a8674a39-4bca-4d47-bd5f-cde978d36e45',
                externalId: undefined,
                name: 'URL Slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *    Insight Page
     */
    insight_page: {
        codename: 'insight_page',
        id: '37a69b22-9aa9-4377-9c63-6d30851cdacf',
        externalId: undefined,
        name: '📄 Insight Page',
        elements: {
            /**
             * Author s  (modular_content)
             */
            authors: {
                codename: 'authors',
                id: '95139afe-6002-4e55-8914-f422cda28819',
                externalId: undefined,
                name: 'Author(s)',
                required: false,
                type: 'modular_content'
            },

            /**
             * Call to Action (modular_content)
             */
            call_to_action: {
                codename: 'call_to_action',
                id: 'c88f6f6c-675b-43ba-8f15-79f93acfc13f',
                externalId: undefined,
                name: 'Call to Action',
                required: false,
                type: 'modular_content'
            },

            /**
             * Capability (taxonomy)
             */
            related_capabilities: {
                codename: 'related_capabilities',
                id: 'c72e01bf-3d07-4eea-bcea-46af43734a07',
                externalId: undefined,
                name: 'Capability',
                required: false,
                type: 'taxonomy'
            },

            /**
             * Card Image (asset)
             */
            card_image: {
                codename: 'card_image',
                id: '697d2a5d-83ae-45a6-a7ea-dc83076b694d',
                externalId: undefined,
                name: 'Card Image',
                required: false,
                type: 'asset'
            },

            /**
             * Contact Card Size (multiple_choice)
             */
            contact_card_size: {
                codename: 'contact_card_size',
                id: '0958a4c7-d899-4ec3-83b4-fc0fa95d5c26',
                externalId: undefined,
                name: 'Contact Card Size',
                required: false,
                type: 'multiple_choice',
                options: {
                    small: {
                        name: 'Small',
                        id: '9a3eda71-46eb-4a65-af24-7c249600047b',
                        codename: 'small',
                        externalId: undefined
                    },
                    large: {
                        name: 'Large',
                        id: 'f39ba76b-2003-4b92-9206-f90bd5a7a059',
                        codename: 'large',
                        externalId: undefined
                    }
                }
            },

            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '8c6b0493-7cad-426e-914c-4208e3c48e3a',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Enable Subscribe Modal (multiple_choice)
             */
            enable_subscribe_modal: {
                codename: 'enable_subscribe_modal',
                id: '4a18f62b-0e17-48fd-b02f-e9db8b0149bf',
                externalId: undefined,
                name: 'Enable Subscribe Modal',
                required: false,
                type: 'multiple_choice',
                options: {
                    enable: {
                        name: 'Enable',
                        id: '97c680e8-ea04-438f-885a-67c645cb711a',
                        codename: 'enable',
                        externalId: undefined
                    },
                    disable: {
                        name: 'Disable',
                        id: '3b263297-45ec-414c-a8ed-7c1631132149',
                        codename: 'disable',
                        externalId: undefined
                    }
                }
            },

            /**
             * Event End (date_time)
             */
            event_end: {
                codename: 'event_end',
                id: 'ea4dfb50-ddfe-44f4-9e55-4b71b5f717df',
                externalId: undefined,
                name: 'Event End',
                required: false,
                type: 'date_time'
            },

            /**
             * Event Start (date_time)
             */
            event_start: {
                codename: 'event_start',
                id: 'f31ff587-6951-4fa2-b5aa-f9f30e9f6df3',
                externalId: undefined,
                name: 'Event Start',
                required: false,
                type: 'date_time'
            },

            /**
             * Insight Category (taxonomy)
             */
            insight_category: {
                codename: 'insight_category',
                id: '7631c61e-3a84-4153-ba9b-3b3504e43b7d',
                externalId: undefined,
                name: 'Insight Category',
                required: true,
                type: 'taxonomy'
            },

            /**
             * Meta Keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '0ca5c9ec-b34f-48df-933b-792568906e4c',
                externalId: undefined,
                name: 'Meta Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Subscription Categories (taxonomy)
             */
            newsletter_category: {
                codename: 'newsletter_category',
                id: '0a7e8caf-5df5-4515-a7e7-5178fd5e6512',
                externalId: undefined,
                name: 'Newsletter Category',
                required: false,
                type: 'taxonomy'
            },

            /**
             * Page URL (custom)
             */
            urls__page_url: {
                codename: 'urls__page_url',
                id: '8d8e9c64-809c-4fb2-af2d-a62f2a368cfc',
                externalId: undefined,
                name: 'Page URL',
                required: false,
                type: 'custom',

                snippetCodename: 'urls'
            },

            /**
             * Presenter s  (modular_content)
             */
            presenters: {
                codename: 'presenters',
                id: '3a814426-2e34-424a-a10f-602c87a77003',
                externalId: undefined,
                name: 'Presenter(s)',
                required: false,
                type: 'modular_content'
            },

            /**
             * Publishing Date (date_time)
             */
            publishing_date: {
                codename: 'publishing_date',
                id: '72b4fe02-b3a2-4dad-a8a7-55ed5a8402cd',
                externalId: undefined,
                name: 'Publishing Date',
                required: false,
                type: 'date_time'
            },

            /**
             * Related People (modular_content)
             */
            related_people: {
                codename: 'related_people',
                id: '2f4308ae-bd5a-41c8-9cc8-6d7c4ae32fb1',
                externalId: undefined,
                name: 'Related People',
                required: false,
                type: 'modular_content'
            },

            /**
             * Short Description (custom)
             */
            short_description: {
                codename: 'short_description',
                id: '10b012a1-0e7f-4dea-a50c-ddfb4a09add7',
                externalId: undefined,
                name: 'Short Description',
                required: false,
                type: 'custom'
            },

            /**
             * Speaker s  (modular_content)
             */
            speakers: {
                codename: 'speakers',
                id: 'e3317635-2a6f-4e3d-a634-766532aa53f6',
                externalId: undefined,
                name: 'Speaker(s)',
                required: false,
                type: 'modular_content'
            },

            /**
             * Subscribe Button (modular_content)
             */
            subscribe_button: {
                codename: 'subscribe_button',
                id: 'db20c811-0487-4e9f-9245-453034360448',
                externalId: undefined,
                name: 'Subscribe Button',
                required: false,
                type: 'modular_content'
            },

            /**
             * Subtitle (text)
             */
            subtitle: {
                codename: 'subtitle',
                id: 'd08001f9-f72c-4508-9dd5-8b4ebe69ecbf',
                externalId: undefined,
                name: 'Subtitle',
                required: false,
                type: 'text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'c762ed3e-8434-41f1-b134-0781628510f6',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: '66ab17e9-bd64-498f-b37e-1633e4c9e36f',
                externalId: undefined,
                name: 'URL Slug',
                required: true,
                type: 'url_slug'
            },

            /**
             * Ventures Industries (taxonomy)
             */
            venture_industries: {
                codename: 'venture_industries',
                id: '8ee4c527-5bf8-4fd6-bff2-113fe72e1ded',
                externalId: undefined,
                name: 'Venture Industries',
                required: false,
                type: 'taxonomy'
            },

            /**
             * Ventures Services (taxonomy)
             */
            venture_services: {
                codename: 'venture_services',
                id: '2d44b493-e151-4c7e-9ce7-b67b8b64b3c8',
                externalId: undefined,
                name: 'Venture Services',
                required: false,
                type: 'taxonomy'
            },

            /**
             * WYSIWYG (custom)
             */
            rich_text_interpreter__rich_text: {
                codename: 'rich_text_interpreter__rich_text',
                id: '5e4e054b-5364-4695-a2e1-05596ed9dc24',
                externalId: undefined,
                name: 'WYSIWYG',
                required: false,
                type: 'custom',

                snippetCodename: 'rich_text_interpreter'
            },

            /**
             * Insights Year (taxonomy)
             */
            insights_year: {
                codename: 'insights_year',
                id: '40b4c9dd-4d21-4fda-a1c4-d00de7a54138',
                externalId: undefined,
                name: 'Year',
                required: true,
                type: 'taxonomy'
            }
        }
    },

    /**
     *    Interior Page
     */
    interior_page: {
        codename: 'interior_page',
        id: '7ac2f89a-e07d-4903-b1f5-0f43a5637917',
        externalId: undefined,
        name: '📄 Interior Page',
        elements: {
            /**
             * Break Component (rich_text)
             */
            break_component: {
                codename: 'break_component',
                id: '7de393ce-ac09-47d4-a4a0-3db1f66019d8',
                externalId: undefined,
                name: 'Break Component',
                required: false,
                type: 'rich_text'
            },

            /**
             * Color (multiple_choice)
             */
            color: {
                codename: 'color',
                id: '22404850-495f-40a1-8ea0-9d9d42345bc9',
                externalId: undefined,
                name: 'Color',
                required: true,
                type: 'multiple_choice',
                options: {
                    blue: {
                        name: 'Blue',
                        id: '790df67c-654b-4629-9992-4e83b06da8fd',
                        codename: 'blue',
                        externalId: undefined
                    },
                    gold: {
                        name: 'Gold',
                        id: 'd5039fd5-6a57-45b5-b420-6ec3c08d587a',
                        codename: 'gold',
                        externalId: undefined
                    },
                    green: {
                        name: 'Green',
                        id: 'b7944757-182f-40b4-8b4b-50f855993a7b',
                        codename: 'green',
                        externalId: undefined
                    },
                    magenta: {
                        name: 'Magenta',
                        id: '5da8f94d-f2f7-46d0-9336-b6d02f38b641',
                        codename: 'magenta',
                        externalId: undefined
                    },
                    orange: {
                        name: 'Orange',
                        id: '60303bae-e637-43fe-a81b-54da9a62d340',
                        codename: 'orange',
                        externalId: undefined
                    },
                    red: {
                        name: 'Red',
                        id: 'f0ca214b-d0d1-4aa1-b7e9-c7e3daff0284',
                        codename: 'red',
                        externalId: undefined
                    }
                }
            },

            /**
             * Hero (modular_content)
             */
            hero: {
                codename: 'hero',
                id: 'c0ba15bb-2672-479f-8ca7-9be100f2b2bf',
                externalId: undefined,
                name: 'Hero',
                required: false,
                type: 'modular_content'
            },

            /**
             * Main Content (rich_text)
             */
            main_content: {
                codename: 'main_content',
                id: '1ee47225-bf7d-404f-998e-f24cfdac5aa4',
                externalId: undefined,
                name: 'Main Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Meta Keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '0ca5c9ec-b34f-48df-933b-792568906e4c',
                externalId: undefined,
                name: 'Meta Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Page URL (custom)
             */
            urls__page_url: {
                codename: 'urls__page_url',
                id: '8d8e9c64-809c-4fb2-af2d-a62f2a368cfc',
                externalId: undefined,
                name: 'Page URL',
                required: false,
                type: 'custom',

                snippetCodename: 'urls'
            },

            /**
             * Show Title (multiple_choice)
             */
            show_title: {
                codename: 'show_title',
                id: 'c68f3a24-c123-49b7-a958-7aa376b921e3',
                externalId: undefined,
                name: 'Show Title',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '2e849dba-ecbf-4824-ae86-0d8916739fd2',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '5999821f-2f8f-40d4-9c33-03de1376f3a9',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            },

            /**
             * Sidebar (rich_text)
             */
            sidebar: {
                codename: 'sidebar',
                id: '27035af5-f9df-4dbf-a6a0-bcce8a3cb28a',
                externalId: undefined,
                name: 'Sidebar',
                required: false,
                type: 'rich_text'
            },

            /**
             * Subpages (subpages)
             */
            subpages: {
                codename: 'subpages',
                id: '75b565bd-1b4e-4771-90df-70b8a9f8fa10',
                externalId: undefined,
                name: 'Subpages',
                required: false,
                type: 'subpages'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'f185c32e-072a-41c3-abdc-119db5ef00d6',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: '06875c84-0fd5-4cf8-8efb-dd8cd2850fcc',
                externalId: undefined,
                name: 'URL Slug',
                required: true,
                type: 'url_slug'
            }
        }
    },

    /**
     *    Location Page
     */
    location_page: {
        codename: 'location_page',
        id: 'a80129f2-6de5-43f6-a37c-06962fba80b1',
        externalId: undefined,
        name: '📄 Location Page',
        elements: {
            /**
             * Additional Address (rich_text)
             */
            additional_address: {
                codename: 'additional_address',
                id: '36ca6dfc-0902-43ad-8184-26076a95bdf1',
                externalId: undefined,
                name: 'Additional Address',
                required: false,
                type: 'rich_text'
            },

            /**
             * Additional Location Image (asset)
             */
            additional_location_image: {
                codename: 'additional_location_image',
                id: '1b5d68c2-f525-46cf-8a0d-19510f6ce71b',
                externalId: undefined,
                name: 'Additional Location Image',
                required: false,
                type: 'asset'
            },

            /**
             * Additional Location Name (text)
             */
            additional_location_title: {
                codename: 'additional_location_title',
                id: '915cebbd-d742-4275-9f60-ae2a61ce2560',
                externalId: undefined,
                name: 'Additional Location Name',
                required: false,
                type: 'text'
            },

            /**
             * Address (rich_text)
             */
            address: {
                codename: 'address',
                id: 'fd051385-32f4-4f8f-9954-d62f541c80f0',
                externalId: undefined,
                name: 'Address',
                required: false,
                type: 'rich_text'
            },

            /**
             * Call to Action (modular_content)
             */
            call_to_action: {
                codename: 'call_to_action',
                id: 'ef52226a-9abe-4dde-bbfc-7efea32c58b2',
                externalId: undefined,
                name: 'Call to Action',
                required: false,
                type: 'modular_content'
            },

            /**
             * Contact (modular_content)
             */
            contact: {
                codename: 'contact',
                id: 'c612da8a-5818-45a4-a018-3d1274c03409',
                externalId: undefined,
                name: 'Contact',
                required: false,
                type: 'modular_content'
            },

            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '040fb375-6eb4-4ce7-b8bf-0ebb750dd991',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Fax Number (rich_text)
             */
            fax_number: {
                codename: 'fax_number',
                id: '70dc6a89-14d5-4d1f-ae9d-3bccfea96f18',
                externalId: undefined,
                name: 'Fax Number',
                required: false,
                type: 'rich_text'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: '5081a9e3-f8f6-452c-b31e-5924aa42025b',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Meta Keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '0ca5c9ec-b34f-48df-933b-792568906e4c',
                externalId: undefined,
                name: 'Meta Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Page URL (custom)
             */
            urls__page_url: {
                codename: 'urls__page_url',
                id: '8d8e9c64-809c-4fb2-af2d-a62f2a368cfc',
                externalId: undefined,
                name: 'Page URL',
                required: false,
                type: 'custom',

                snippetCodename: 'urls'
            },

            /**
             * Phone Number (rich_text)
             */
            phone_number: {
                codename: 'phone_number',
                id: '44298e41-716c-4c2a-88c0-93aeb5635ed6',
                externalId: undefined,
                name: 'Phone Number',
                required: false,
                type: 'rich_text'
            },

            /**
             * Primary Location Name (text)
             */
            primary_location_name: {
                codename: 'primary_location_name',
                id: '43d104c6-cbd4-409c-99f2-414fd8985808',
                externalId: undefined,
                name: 'Primary Location Name',
                required: false,
                type: 'text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '9bd222d7-cbe2-49ed-98ac-ce9bcae33ded',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: 'bd968b9f-5137-4148-9694-5129c830ec3f',
                externalId: undefined,
                name: 'URL Slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *    People Page
     */
    people_page: {
        codename: 'people_page',
        id: '5c842188-19ba-477d-9579-cfa7143b7fda',
        externalId: undefined,
        name: '📄 People Page',
        elements: {
            /**
             * Alias (text)
             */
            people_alias: {
                codename: 'people_alias',
                id: '92586606-f3c0-44c3-922e-3ccebdc2d9a8',
                externalId: undefined,
                name: 'Alias',
                required: false,
                type: 'text'
            },

            /**
             * Bio (rich_text)
             *
             * This section houses the page's text content, which will be truncated by the Read More item below if a character count is defined.
             */
            content_bio: {
                codename: 'content_bio',
                id: '8588d6d9-f9ba-4e31-ba01-2db658c62c7f',
                externalId: undefined,
                name: 'Bio',
                required: false,
                type: 'rich_text'
            },

            /**
             * Break Component (rich_text)
             *
             * The Break Component spans the width of the page.  Use this for a 🧩Split Content Block housing a 🧩Capability List or a different content model that you want to display as full width.
             */
            break_component: {
                codename: 'break_component',
                id: 'd6fb7199-7f11-40cf-a952-5506e08e9c97',
                externalId: undefined,
                name: 'Break Component',
                required: false,
                type: 'rich_text'
            },

            /**
             * City (text)
             */
            address__city: {
                codename: 'address__city',
                id: '0818c920-ed20-4f1f-aceb-46042011c65d',
                externalId: undefined,
                name: 'City',
                required: false,
                type: 'text',

                snippetCodename: 'address'
            },

            /**
             * Color (multiple_choice)
             *
             * A color can be selected to color match a Person's profile to their capability if desired.  If none is selected, then the default Manatt Gold is applied.
             */
            color: {
                codename: 'color',
                id: '8d665f87-8df2-4c9b-a77b-52bc53e6c7d2',
                externalId: undefined,
                name: 'Color',
                required: false,
                type: 'multiple_choice',
                options: {
                    blue: {
                        name: 'Blue',
                        id: 'afb8b99d-afd3-41d6-acef-3c6c1bf59b23',
                        codename: 'blue',
                        externalId: undefined
                    },
                    gold: {
                        name: 'Gold',
                        id: 'c39cc932-ce21-4932-a6dc-1d314dae52cc',
                        codename: 'gold',
                        externalId: undefined
                    },
                    green: {
                        name: 'Green',
                        id: 'c3b5e4f8-1d56-46a1-876f-b6944e6216f3',
                        codename: 'green',
                        externalId: undefined
                    },
                    magenta: {
                        name: 'Magenta',
                        id: '200b0e97-caef-421d-a80f-cbe896b2f206',
                        codename: 'magenta',
                        externalId: undefined
                    },
                    orange: {
                        name: 'Orange',
                        id: 'c171e03d-f0f1-4e1d-a01f-1348d94f5d3a',
                        codename: 'orange',
                        externalId: undefined
                    },
                    red: {
                        name: 'Red',
                        id: '193ed1c8-d948-45c8-8d7a-4f4dbcf9a6a1',
                        codename: 'red',
                        externalId: undefined
                    }
                }
            },

            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '75720889-f63d-4782-ae4a-734ae7536cf0',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Country (text)
             */
            address__country: {
                codename: 'address__country',
                id: 'adc1b6a5-8bfe-495d-8f46-26caec9dacbf',
                externalId: undefined,
                name: 'Country',
                required: false,
                type: 'text',

                snippetCodename: 'address'
            },

            /**
             * Display Name (text)
             */
            display_name: {
                codename: 'display_name',
                id: '6640e449-1b9c-4831-8c53-7f588b4d3fea',
                externalId: undefined,
                name: 'Display Name',
                required: true,
                type: 'text'
            },

            /**
             * Email (text)
             */
            email: {
                codename: 'email',
                id: '163c5b33-e0e5-4cf1-b4e4-b236703968eb',
                externalId: undefined,
                name: 'Email',
                required: false,
                type: 'text'
            },

            /**
             * First Name (text)
             */
            first_name: {
                codename: 'first_name',
                id: '784ecc8f-f17d-4496-b748-510a60bb9066',
                externalId: undefined,
                name: 'First Name',
                required: true,
                type: 'text'
            },

            /**
             * Image (asset)
             *
             * Image sizing recommendations - 1x1 ratio, at least 500 x 500px.
             */
            people_image: {
                codename: 'people_image',
                id: '123a1e4e-6616-4dd5-8adf-395e028a920a',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Title (taxonomy)
             *
             * This field is for Job Title pulled from the Taxonomy list of Titles.
             */
            people_title: {
                codename: 'people_title',
                id: 'b6a5ccbe-387e-49c9-9c0a-367b8ece7616',
                externalId: undefined,
                name: 'Job Title',
                required: false,
                type: 'taxonomy'
            },

            /**
             * Last Name (text)
             */
            last_name: {
                codename: 'last_name',
                id: '876d7f78-cd31-4608-8e43-17de0832e36c',
                externalId: undefined,
                name: 'Last Name',
                required: true,
                type: 'text'
            },

            /**
             * Location (modular_content)
             */
            people_location: {
                codename: 'people_location',
                id: '3eaef368-ed9c-481d-8af5-04639166501d',
                externalId: undefined,
                name: 'Location',
                required: false,
                type: 'modular_content'
            },

            /**
             * Meta Keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '0ca5c9ec-b34f-48df-933b-792568906e4c',
                externalId: undefined,
                name: 'Meta Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Page Title (text)
             *
             * This controls the large h1 Header on the page.  Please input the Person's desired display name here.
             */
            title: {
                codename: 'title',
                id: '503caeee-3843-4cd7-bdc2-c1a39ae55c43',
                externalId: undefined,
                name: 'Page Title',
                required: true,
                type: 'text'
            },

            /**
             * Page URL (custom)
             */
            urls__page_url: {
                codename: 'urls__page_url',
                id: '8d8e9c64-809c-4fb2-af2d-a62f2a368cfc',
                externalId: undefined,
                name: 'Page URL',
                required: false,
                type: 'custom',

                snippetCodename: 'urls'
            },

            /**
             * Phone Number (rich_text)
             */
            people_phone_number: {
                codename: 'people_phone_number',
                id: 'a9be239f-917e-46a9-b514-89f46d677733',
                externalId: undefined,
                name: 'Phone Number',
                required: false,
                type: 'rich_text'
            },

            /**
             * Position Title (text)
             */
            position_title: {
                codename: 'position_title',
                id: 'f07074bf-7eef-414c-b49e-d83cbeaee9f3',
                externalId: undefined,
                name: 'Position Title',
                required: false,
                type: 'text'
            },

            /**
             * Postal Code (text)
             */
            address__postal_code: {
                codename: 'address__postal_code',
                id: '3928a792-2ce4-43c8-9d32-3c31d257485a',
                externalId: undefined,
                name: 'Postal Code',
                required: false,
                type: 'text',

                snippetCodename: 'address'
            },

            /**
             * Capability (taxonomy)
             *
             * The selected Capabilities will link this person to other content tagged with the same Capability to populate the Insights and Team lists.
             */
            content_primary_capabilities: {
                codename: 'content_primary_capabilities',
                id: '4cccd4ba-d57e-40a3-a64b-1786894a2d57',
                externalId: undefined,
                name: 'Primary Capability',
                required: false,
                type: 'taxonomy'
            },

            /**
             * Read More Character Count (number)
             *
             * Controls the amount of text content shown before a Read More item appears.  Character count includes spaces and punctuation.  You can check the character count for a body of content via a word processor app such as MS Word, Google Docs, etc. to curate this.
             */
            content_read_more: {
                codename: 'content_read_more',
                id: '6059d060-637f-47e6-8971-284f09178ba7',
                externalId: undefined,
                name: 'Read More Character Count',
                required: false,
                type: 'number'
            },

            /**
             * Capability (taxonomy)
             */
            content_capabilities: {
                codename: 'content_capabilities',
                id: '20ec2dad-5339-44c7-82df-6e0cd1fa11fe',
                externalId: undefined,
                name: 'Related Capabilities',
                required: false,
                type: 'taxonomy'
            },

            /**
             * Related Content (rich_text)
             *
             * Content 🧩puzzle pieces with custom layouts/designs such as an 🧩 Insights list, 🧩Content Block for Experience List, 🧩 Awards List, 🧩 Tab Content Block for 🔠 Accomplishment Section can live here.
             */
            content_related_content: {
                codename: 'content_related_content',
                id: 'f90251dd-445d-415b-84ff-71be265eec3c',
                externalId: undefined,
                name: 'Related Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * State (text)
             */
            address__state: {
                codename: 'address__state',
                id: 'a77e1b6b-c3d6-4342-a488-c263f11c623f',
                externalId: undefined,
                name: 'State',
                required: false,
                type: 'text',

                snippetCodename: 'address'
            },

            /**
             * Street (text)
             */
            address__street: {
                codename: 'address__street',
                id: '76897904-afc4-40a8-ad01-b0b35a31eca7',
                externalId: undefined,
                name: 'Street',
                required: false,
                type: 'text',

                snippetCodename: 'address'
            },

            /**
             * Sub Title (text)
             */
            sub_title: {
                codename: 'sub_title',
                id: '5c8f8d51-a09f-4bbf-8fc8-cd972b45ba6d',
                externalId: undefined,
                name: 'Sub Title',
                required: false,
                type: 'text'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: 'b33aee0f-d12e-436e-9c80-ff6e85bb4065',
                externalId: undefined,
                name: 'URL Slug',
                required: true,
                type: 'url_slug'
            },

            /**
             * vCard (asset)
             */
            vcard: {
                codename: 'vcard',
                id: '628bf17c-8256-4046-822f-730f7dd3cee0',
                externalId: undefined,
                name: 'vCard',
                required: false,
                type: 'asset'
            }
        }
    },

    /**
     *    Accomplishment Item
     */
    accomplishment_item: {
        codename: 'accomplishment_item',
        id: '97a2faa3-54b1-452a-95b4-0f75c8fc2228',
        externalId: undefined,
        name: '🔠 Accomplishment Item',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: 'fb74dfd0-2898-44e5-9030-ace17a18fbaf',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Eyebrow (multiple_choice)
             */
            eyebrow: {
                codename: 'eyebrow',
                id: '19b28e6a-c194-47bb-9716-e0446809fa8a',
                externalId: undefined,
                name: 'Eyebrow',
                required: false,
                type: 'multiple_choice',
                options: {
                    panelist: {
                        name: 'Panelist',
                        id: '985682ae-9f04-4d6d-a384-214194748e5f',
                        codename: 'panelist',
                        externalId: undefined
                    },
                    speaker: {
                        name: 'Speaker',
                        id: 'd9b9d916-087d-4e2f-91d0-6ac300827c58',
                        codename: 'speaker',
                        externalId: undefined
                    },
                    co_speaker: {
                        name: 'Co Speaker',
                        id: '44a87166-48f4-4fd9-a0b7-4fc33fe4a532',
                        codename: 'co_speaker',
                        externalId: undefined
                    },
                    presenter: {
                        name: 'Presenter',
                        id: 'adcdc734-b1ff-4b97-a5f9-a3e263d62fba',
                        codename: 'presenter',
                        externalId: undefined
                    },
                    co_presenter: {
                        name: 'Co Presenter',
                        id: '3e5b59f5-3d10-4c2d-8d85-c6d0fdca3056',
                        codename: 'co_presenter',
                        externalId: undefined
                    },
                    moderator: {
                        name: 'Moderator',
                        id: '088afd6b-b92b-4d37-9203-ae8d3f214aad',
                        codename: 'moderator',
                        externalId: undefined
                    },
                    chairman: {
                        name: 'Chairman',
                        id: '6611881f-a792-41c5-aeeb-98d38f20ea67',
                        codename: 'chairman',
                        externalId: undefined
                    },
                    member: {
                        name: 'Member',
                        id: '31165628-c624-4b6e-912e-df3ce9d64f1a',
                        codename: 'member',
                        externalId: undefined
                    }
                }
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '27e26776-12f3-4c1e-8a64-a3fc0d9fe038',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Accomplishment Section
     */
    accomplishment_section: {
        codename: 'accomplishment_section',
        id: '32e72dd1-aa8f-4154-8a7e-871b82445f0c',
        externalId: undefined,
        name: '🔠 Accomplishment Section',
        elements: {
            /**
             *   of Shown Items (number)
             */
            _of_shown_items: {
                codename: '_of_shown_items',
                id: '282fad1f-72fb-466f-84c8-7d809d5724fa',
                externalId: undefined,
                name: '# of Shown Items',
                required: false,
                type: 'number'
            },

            /**
             * Accomplishment Items (modular_content)
             */
            accomplishment_items: {
                codename: 'accomplishment_items',
                id: '3118f09d-c259-4511-a798-f5de0d983930',
                externalId: undefined,
                name: 'Accomplishment Items',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'c8d7afe5-c36d-4ba3-be00-9b4cef7c75bd',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Accordion Item
     */
    accordion_item: {
        codename: 'accordion_item',
        id: '2ec4e441-b46f-4750-bb3b-c4ce11db8a5f',
        externalId: undefined,
        name: '🔠 Accordion Item',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '917813a8-3e46-42cf-b42b-8f7f50bc191d',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'a42573d9-7082-4f27-a621-9b8baf445ddf',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Award
     */
    award: {
        codename: 'award',
        id: 'dd4dafbb-e38a-42c6-9700-c6abd9665909',
        externalId: undefined,
        name: '🔠 Award',
        elements: {
            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: '724354f5-bbf1-4823-b971-d12d5f1d32b4',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Linked Target (url_slug)
             */
            linked_target: {
                codename: 'linked_target',
                id: '358c0747-68e1-42d6-a025-e96d5bc821e4',
                externalId: undefined,
                name: 'Linked Target',
                required: false,
                type: 'url_slug'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '74ba06e5-7fb6-41b3-afa3-008a92890784',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Call to Action
     */
    call_to_action: {
        codename: 'call_to_action',
        id: 'fd7e0345-9303-476e-9481-cfb95580d00a',
        externalId: undefined,
        name: '🔠 Call to Action',
        elements: {
            /**
             * Color (multiple_choice)
             */
            color: {
                codename: 'color',
                id: 'a05694d7-27ec-487a-9d65-d114b9d308eb',
                externalId: undefined,
                name: 'Color',
                required: false,
                type: 'multiple_choice',
                options: {
                    use_page_theme: {
                        name: 'Use Page Theme',
                        id: '90d4ca60-0cb8-468b-9472-ff6ac82d5fd0',
                        codename: 'use_page_theme',
                        externalId: undefined
                    },
                    blue: {
                        name: 'Blue',
                        id: '27125a92-d56f-4ec4-a918-dd28701d1b0a',
                        codename: 'blue',
                        externalId: undefined
                    },
                    gold: {
                        name: 'Gold',
                        id: 'bb0a0901-9305-40d0-a29f-c525d358e713',
                        codename: 'gold',
                        externalId: undefined
                    },
                    green: {
                        name: 'Green',
                        id: '3a3fde5c-9be2-4a64-98f4-bdb8f7611f6e',
                        codename: 'green',
                        externalId: undefined
                    },
                    magenta: {
                        name: 'Magenta',
                        id: 'ddca5bbb-4759-4c3e-93f1-5a8d5df0b73a',
                        codename: 'magenta',
                        externalId: undefined
                    },
                    orange: {
                        name: 'Orange',
                        id: '3a3ecb7a-c887-46b8-9ceb-138b53cd4306',
                        codename: 'orange',
                        externalId: undefined
                    },
                    red: {
                        name: 'Red',
                        id: '6da59323-9a12-440c-9547-699b56fbca98',
                        codename: 'red',
                        externalId: undefined
                    }
                }
            },

            /**
             * Email (rich_text)
             */
            email: {
                codename: 'email',
                id: '0464adfd-033e-4d92-9778-2bdf06f8d358',
                externalId: undefined,
                name: 'Email',
                required: false,
                type: 'rich_text'
            },

            /**
             * Generic Asset Target (asset)
             */
            generic_asset_target: {
                codename: 'generic_asset_target',
                id: 'b04979b8-964f-49b3-938c-c0b19ffe716a',
                externalId: undefined,
                name: 'Generic Asset Target',
                required: false,
                type: 'asset'
            },

            /**
             * Link Title (text)
             */
            link_title: {
                codename: 'link_title',
                id: 'c274394e-3c99-48f7-9810-3a0f805dc454',
                externalId: undefined,
                name: 'Link Title',
                required: true,
                type: 'text'
            },

            /**
             * Linked Target (modular_content)
             */
            linked_target: {
                codename: 'linked_target',
                id: 'caccfea3-ab08-46c9-9e84-5dc96562a643',
                externalId: undefined,
                name: 'Linked Target',
                required: false,
                type: 'modular_content'
            },

            /**
             * Manual Target (text)
             */
            manual_target: {
                codename: 'manual_target',
                id: '34408d5a-0d65-4353-8c97-2c5ca68f1417',
                externalId: undefined,
                name: 'Manual Target',
                required: false,
                type: 'text'
            },

            /**
             * Phone Number (rich_text)
             */
            phone_number: {
                codename: 'phone_number',
                id: '4254a97f-4895-4c47-8b27-6abfc44639a3',
                externalId: undefined,
                name: 'Phone Number',
                required: false,
                type: 'rich_text'
            }
        }
    },

    /**
     *    Card Modal
     */
    _card_modal: {
        codename: '_card_modal',
        id: 'e04d60f1-555f-49cc-afb9-80720b7917a2',
        externalId: undefined,
        name: '🔠 Card Modal',
        elements: {
            /**
             * CTA (modular_content)
             */
            cta: {
                codename: 'cta',
                id: '31ac73a6-1f51-4597-8af2-2c23a0715cf1',
                externalId: undefined,
                name: 'CTA',
                required: false,
                type: 'modular_content'
            },

            /**
             * Description (rich_text)
             */
            description: {
                codename: 'description',
                id: 'ee2ae70f-c0d6-4fa9-959d-d9a3f294adc2',
                externalId: undefined,
                name: 'Description',
                required: false,
                type: 'rich_text'
            },

            /**
             * Icon (text)
             *
             * Font-awesome icon (e.x. fa-heartbeat)
             */
            icon: {
                codename: 'icon',
                id: 'f38a0d32-3852-4b39-90f0-9eca66d445a4',
                externalId: undefined,
                name: 'Icon',
                required: false,
                type: 'text'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: 'c3b2c848-c759-4d1a-8706-eba2b840286e',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'c4ff9c54-e0ed-4dde-9f37-e2639eb6aea3',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            },

            /**
             * Ventures Industries (taxonomy)
             */
            ventures_industry: {
                codename: 'ventures_industry',
                id: '379d403d-dc58-45e4-9e73-bcf7961da927',
                externalId: undefined,
                name: 'Ventures Industry',
                required: false,
                type: 'taxonomy'
            },

            /**
             * Ventures Services (taxonomy)
             */
            ventures_service: {
                codename: 'ventures_service',
                id: '58ae4b53-6248-48bb-ba7c-8a48a9e8c22e',
                externalId: undefined,
                name: 'Ventures Service',
                required: false,
                type: 'taxonomy'
            },

            /**
             * WYSIWYG (custom)
             */
            rich_text_interpreter__rich_text: {
                codename: 'rich_text_interpreter__rich_text',
                id: '5e4e054b-5364-4695-a2e1-05596ed9dc24',
                externalId: undefined,
                name: 'WYSIWYG',
                required: false,
                type: 'custom',

                snippetCodename: 'rich_text_interpreter'
            }
        }
    },

    /**
     *    Client
     */
    client: {
        codename: 'client',
        id: '9b7abbc3-e8b4-4a24-9813-b53293a7a3f9',
        externalId: undefined,
        name: '🔠 Client',
        elements: {
            /**
             * Client Name (text)
             */
            client_name: {
                codename: 'client_name',
                id: '5b012f66-a912-4aa7-9bd9-fceb9f9d7043',
                externalId: undefined,
                name: 'Client Name',
                required: false,
                type: 'text'
            },

            /**
             * Description (rich_text)
             */
            description: {
                codename: 'description',
                id: '43817317-e71b-4e35-9505-241af0aa1eb8',
                externalId: undefined,
                name: 'Description',
                required: false,
                type: 'rich_text'
            }
        }
    },

    /**
     *    Content   Asset w  Link
     */
    _content___asset_w__link: {
        codename: '_content___asset_w__link',
        id: 'f3f18dc7-dbf1-4f2d-a9fe-7600b8856919',
        externalId: undefined,
        name: '🔠 Content - Asset w/ Link',
        elements: {
            /**
             * Alignment (multiple_choice)
             */
            alignment: {
                codename: 'alignment',
                id: 'd4a25234-6eb1-4e26-9b4e-24f8af17840d',
                externalId: undefined,
                name: 'Alignment',
                required: false,
                type: 'multiple_choice',
                options: {
                    left: {
                        name: 'Left',
                        id: '5a1e5fb3-ed6e-4f5b-986f-980382ef798b',
                        codename: 'left',
                        externalId: undefined
                    },
                    centered: {
                        name: 'Centered',
                        id: '39e1d57e-a3b8-4376-9d48-a3b99004d7f0',
                        codename: 'centered',
                        externalId: undefined
                    },
                    right: {
                        name: 'Right',
                        id: '81dea0c1-f862-4d46-880e-695eb7d9a15c',
                        codename: 'right',
                        externalId: undefined
                    }
                }
            },

            /**
             * Asset (asset)
             */
            asset: {
                codename: 'asset',
                id: 'cba83f81-4aa4-4193-9759-3c267dfc4588',
                externalId: undefined,
                name: 'Asset',
                required: false,
                type: 'asset'
            },

            /**
             * Kontent Page (modular_content)
             */
            kontent_page: {
                codename: 'kontent_page',
                id: '0f86e592-d34c-4fa3-8ee9-5246dfccb336',
                externalId: undefined,
                name: 'Kontent Page',
                required: false,
                type: 'modular_content'
            },

            /**
             * Link URL (text)
             */
            link_url: {
                codename: 'link_url',
                id: 'cfd1799d-61a9-474f-b416-66423f57d05c',
                externalId: undefined,
                name: 'Link URL',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Content   Blockquote
     */
    _content___blockquote: {
        codename: '_content___blockquote',
        id: '7344e6e7-f7b1-46c0-98d0-1d88c807320d',
        externalId: undefined,
        name: '🔠 Content - Blockquote',
        elements: {}
    },

    /**
     *    Content   Horizontal Line
     */
    _content___horizontal_line: {
        codename: '_content___horizontal_line',
        id: '64960796-cc0e-4d3d-aca1-b47f6114b921',
        externalId: undefined,
        name: '🔠 Content - Horizontal Line',
        elements: {}
    },

    /**
     *    Content   Image Wrap
     */
    _content___image_wrap: {
        codename: '_content___image_wrap',
        id: 'd0e61e4e-fa49-46b4-a388-ac2e0ce91d62',
        externalId: undefined,
        name: '🔠 Content - Image Wrap',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '593818c3-a617-4fa3-afcb-77ad22061829',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: '7e8bbb66-ff91-4dd9-ac81-1429bcea9b94',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Item Name (text)
             */
            item_name: {
                codename: 'item_name',
                id: 'ea3a5696-87ff-4b9a-8b3e-f574c4b5ed65',
                externalId: undefined,
                name: 'Item Name',
                required: false,
                type: 'text'
            },

            /**
             * Layout (multiple_choice)
             */
            layout: {
                codename: 'layout',
                id: '0de839e8-bb17-428c-b2bd-372cf1f652ed',
                externalId: undefined,
                name: 'Layout',
                required: false,
                type: 'multiple_choice',
                options: {
                    left: {
                        name: 'Left',
                        id: '13d3efdc-f613-4efc-8316-0864fcab6c2c',
                        codename: 'left',
                        externalId: undefined
                    },
                    right: {
                        name: 'Right',
                        id: '2bdcd973-5f20-48b6-a337-232804acf697',
                        codename: 'right',
                        externalId: undefined
                    }
                }
            }
        }
    },

    /**
     *    Content   Text Color
     */
    _content___text_color: {
        codename: '_content___text_color',
        id: 'b6971d1c-3bdc-4d1e-a33b-5c08fab97882',
        externalId: undefined,
        name: '🔠 Content - Text Color',
        elements: {
            /**
             * Text Color (multiple_choice)
             */
            text_color: {
                codename: 'text_color',
                id: 'd2c9b769-3a6f-4c56-8185-ce0908d16f5e',
                externalId: undefined,
                name: 'Text Color',
                required: false,
                type: 'multiple_choice',
                options: {
                    blue: {
                        name: 'Blue',
                        id: '41b2ba09-2bb7-4bb1-a9cd-6af51918f04c',
                        codename: 'blue',
                        externalId: undefined
                    },
                    gold: {
                        name: 'Gold',
                        id: '792dbf71-9a3b-42d7-aff2-d13cb8c89c05',
                        codename: 'gold',
                        externalId: undefined
                    },
                    green: {
                        name: 'Green',
                        id: '05739c1f-9a46-4e01-b344-b8fcea870d07',
                        codename: 'green',
                        externalId: undefined
                    },
                    magenta: {
                        name: 'Magenta',
                        id: '4bc1a1ad-14c1-4413-81ec-8eb2a8eeffb7',
                        codename: 'magenta',
                        externalId: undefined
                    },
                    orange: {
                        name: 'Orange',
                        id: 'bca1439d-a406-4580-94bc-362df989d8aa',
                        codename: 'orange',
                        externalId: undefined
                    },
                    red: {
                        name: 'Red',
                        id: '4869af1f-bf7c-4a78-b7ee-daf8452a4286',
                        codename: 'red',
                        externalId: undefined
                    }
                }
            }
        }
    },

    /**
     *    Content   Underline Text
     */
    _content___underline_text: {
        codename: '_content___underline_text',
        id: 'd4c80790-750a-413c-954e-bba8ed6b0e36',
        externalId: undefined,
        name: '🔠 Content - Underline Text',
        elements: {
            /**
             * Text (text)
             */
            text: {
                codename: 'text',
                id: 'bbaac471-5f1a-4cef-85f5-146aaaefddb3',
                externalId: undefined,
                name: 'Text',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Home Hero Slide
     */
    home_hero_slide: {
        codename: 'home_hero_slide',
        id: '8d989898-e269-4ae0-93e6-75f376149329',
        externalId: undefined,
        name: '🔠 Home Hero Slide',
        elements: {
            /**
             * Call to Action (modular_content)
             */
            call_to_action: {
                codename: 'call_to_action',
                id: '840ecdce-86e7-43ce-8302-ddd9682dc42a',
                externalId: undefined,
                name: 'Call to Action',
                required: false,
                type: 'modular_content'
            },

            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '24c520d1-81a3-46ef-9ff1-8c95253ad85c',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: 'b915c7d8-87d1-4269-912e-c56bd84cc41b',
                externalId: undefined,
                name: 'Image',
                required: true,
                type: 'asset'
            },

            /**
             * LinkedIn (text)
             */
            linkedin: {
                codename: 'linkedin',
                id: '31a49112-86f4-4c3c-be95-d9c9af5be1cf',
                externalId: undefined,
                name: 'LinkedIn',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Interior Hero Slide
     */
    interior_hero_slide: {
        codename: 'interior_hero_slide',
        id: '09759bb5-14d7-4775-8fc7-8df8d6ae4d24',
        externalId: undefined,
        name: '🔠 Interior Hero Slide',
        elements: {
            /**
             * Call to Action (modular_content)
             */
            call_to_action: {
                codename: 'call_to_action',
                id: 'a893a9b5-1a04-4d99-83a0-1d0e4eb610d1',
                externalId: undefined,
                name: 'Call to Action',
                required: false,
                type: 'modular_content'
            },

            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '4526bdec-1e7d-449b-9365-721238ed3b87',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: 'a3f35170-7bf4-4e18-bcb2-d78803c7f46b',
                externalId: undefined,
                name: 'Image',
                required: true,
                type: 'asset'
            },

            /**
             * LinkedIn (text)
             */
            linkedin: {
                codename: 'linkedin',
                id: '43c24838-57bf-4021-b99f-857bae76bc9b',
                externalId: undefined,
                name: 'LinkedIn',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Quick Fact
     */
    quick_fact: {
        codename: 'quick_fact',
        id: 'f8f247d2-77d0-48a1-a0eb-d5b063dffe07',
        externalId: undefined,
        name: '🔠 Quick Fact',
        elements: {
            /**
             * Eyebrow (text)
             */
            eyebrow: {
                codename: 'eyebrow',
                id: '7d0490d6-5bba-474f-8511-5a4d7f0a3468',
                externalId: undefined,
                name: 'Eyebrow',
                required: false,
                type: 'text'
            },

            /**
             * Linked Content (modular_content)
             */
            linked_content: {
                codename: 'linked_content',
                id: '9d82ded9-3d48-46e6-abef-b586931c9d76',
                externalId: undefined,
                name: 'Linked Content',
                required: false,
                type: 'modular_content'
            },

            /**
             * Style (multiple_choice)
             */
            style: {
                codename: 'style',
                id: '3d695f9e-1818-4b04-8767-62be5d9e9f27',
                externalId: undefined,
                name: 'Style',
                required: false,
                type: 'multiple_choice',
                options: {
                    full_width: {
                        name: 'Full Width',
                        id: '37f995f9-8867-414a-9e03-2428f75f2f71',
                        codename: 'full_width',
                        externalId: undefined
                    },
                    half_width: {
                        name: 'Half Width',
                        id: '9ecb50cd-8293-4ed2-87ac-a59a477a88da',
                        codename: 'half_width',
                        externalId: undefined
                    }
                }
            },

            /**
             * Text (text)
             */
            text: {
                codename: 'text',
                id: '0324f10f-40fb-43ce-bd33-a20d0e74f5d9',
                externalId: undefined,
                name: 'Text',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Reference
     */
    _reference: {
        codename: '_reference',
        id: '6fa96222-6091-41e5-a800-90e36d2eef0a',
        externalId: undefined,
        name: '🔠 Reference',
        elements: {
            /**
             * Reference Order Number (number)
             */
            reference_order_number: {
                codename: 'reference_order_number',
                id: '60d6ffe8-660c-4ab3-81e2-2690b41a307b',
                externalId: undefined,
                name: 'Reference Order Number',
                required: false,
                type: 'number'
            },

            /**
             * Reference Text (rich_text)
             */
            reference_text: {
                codename: 'reference_text',
                id: 'f2145ac4-2c4d-4a69-a1f7-546249da1a8a',
                externalId: undefined,
                name: 'Reference Text',
                required: false,
                type: 'rich_text'
            }
        }
    },

    /**
     *    Split Content   Basic Content Block
     */
    split_content__basic_content_block: {
        codename: 'split_content__basic_content_block',
        id: '2c7fe5bb-0ed7-4d32-a4c4-0aeedb3211ed',
        externalId: undefined,
        name: '🔠 Split Content - Basic Content Block',
        elements: {
            /**
             * Call to Action (modular_content)
             */
            call_to_action: {
                codename: 'call_to_action',
                id: '0e6f1665-f3af-4136-9666-91e01a69282f',
                externalId: undefined,
                name: 'Call to Action',
                required: false,
                type: 'modular_content'
            },

            /**
             * Content (rich_text)
             */
            content_: {
                codename: 'content_',
                id: 'afc05a08-b1db-413c-9846-8cbb99e6bb40',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Read More Character Count (number)
             */
            read_more_character_count: {
                codename: 'read_more_character_count',
                id: 'c6a547a2-d166-4b5d-b0c7-516024699112',
                externalId: undefined,
                name: 'Read More Character Count',
                required: false,
                type: 'number'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '213e09ef-af35-453b-9446-926a01184386',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Streaming Royalty Parameters
     */
    streaming_royalty_parameters: {
        codename: 'streaming_royalty_parameters',
        id: '90a60eaa-7002-4ae5-8658-063325012965',
        externalId: undefined,
        name: '🔠 Streaming Royalty Parameters',
        elements: {
            /**
             * Per Play Label Royalty (number)
             */
            per_play_label_royalty: {
                codename: 'per_play_label_royalty',
                id: '422a80a4-fd4b-44b3-aca0-9409b0cf3a45',
                externalId: undefined,
                name: 'Per Play Label Royalty',
                required: true,
                type: 'number'
            },

            /**
             * Per Play Mechanical Royalty (number)
             */
            per_play_mechanical_royalty: {
                codename: 'per_play_mechanical_royalty',
                id: '28b146a9-1fbe-412c-b005-e2d4a0283fe9',
                externalId: undefined,
                name: 'Per Play Mechanical Royalty',
                required: true,
                type: 'number'
            },

            /**
             * Per Play Performance Royalty (number)
             */
            per_play_performance_royalty: {
                codename: 'per_play_performance_royalty',
                id: 'c8940057-b578-43ea-a373-f9618349145c',
                externalId: undefined,
                name: 'Per Play Performance Royalty',
                required: true,
                type: 'number'
            },

            /**
             * Streaming Company (multiple_choice)
             */
            streaming_company: {
                codename: 'streaming_company',
                id: 'b8cb8dc0-0ceb-4849-8f74-848ac3d2965c',
                externalId: undefined,
                name: 'Streaming Company',
                required: true,
                type: 'multiple_choice',
                options: {
                    spotify: {
                        name: 'Spotify',
                        id: '3932a20d-c2cb-4eb9-bea9-8df1f6268a6f',
                        codename: 'spotify',
                        externalId: undefined
                    },
                    apple_music: {
                        name: 'Apple Music',
                        id: '8375d93d-99fb-44ed-8c8b-44f15f9f6081',
                        codename: 'apple_music',
                        externalId: undefined
                    }
                }
            },

            /**
             * Total Per Play Royalty (number)
             */
            total_per_play_royalty: {
                codename: 'total_per_play_royalty',
                id: '0c7bea8b-e274-4786-9123-a037d7a81f74',
                externalId: undefined,
                name: 'Total Per Play Royalty',
                required: true,
                type: 'number'
            }
        }
    },

    /**
     *    Subscription Item
     */
    _subscription_item: {
        codename: '_subscription_item',
        id: 'f5afc460-a272-4d8c-83ae-67b71d0f0ce4',
        externalId: undefined,
        name: '🔠 Subscription Item',
        elements: {
            /**
             * Display Name (text)
             */
            display_name: {
                codename: 'display_name',
                id: '3633fb0f-6ad7-4654-8d49-cb8744b04e6d',
                externalId: undefined,
                name: 'Display Name',
                required: false,
                type: 'text'
            },

            /**
             * Pardot ID (text)
             */
            pardot_id: {
                codename: 'pardot_id',
                id: '06e0bc57-34c8-485e-ac7c-628c29b6badc',
                externalId: undefined,
                name: 'Pardot ID',
                required: false,
                type: 'text'
            },

            /**
             * Subscription Categories (taxonomy)
             */
            capabilities: {
                codename: 'capabilities',
                id: '2bf5f7e3-c3f4-4c75-a4b7-418dfbf28215',
                externalId: undefined,
                name: 'Subscription Categories',
                required: true,
                type: 'taxonomy'
            }
        }
    },

    /**
     *    Tab Section
     */
    tab_section: {
        codename: 'tab_section',
        id: '223a0ed1-a9bd-4362-8211-0a340a82d52e',
        externalId: undefined,
        name: '🔠 Tab Section',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: 'eba8cde8-e0fa-4140-8f27-1606d336278d',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Read More Character Count (number)
             */
            read_more_character_count: {
                codename: 'read_more_character_count',
                id: '69d31322-e032-4231-bf82-b4f8a70cc685',
                externalId: undefined,
                name: 'Read More Character Count',
                required: false,
                type: 'number'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '84c43930-ef32-4398-880e-8ef8f15692a9',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * WYSIWYG (custom)
             */
            rich_text_interpreter__rich_text: {
                codename: 'rich_text_interpreter__rich_text',
                id: '5e4e054b-5364-4695-a2e1-05596ed9dc24',
                externalId: undefined,
                name: 'WYSIWYG',
                required: false,
                type: 'custom',

                snippetCodename: 'rich_text_interpreter'
            }
        }
    },

    /**
     *    Table Cell
     */
    table_cell: {
        codename: 'table_cell',
        id: '91bf8733-3fca-488f-850e-927e4bd067b9',
        externalId: undefined,
        name: '🔠 Table Cell',
        elements: {
            /**
             * Cell Style (multiple_choice)
             */
            cell_style: {
                codename: 'cell_style',
                id: 'b5bcc678-0492-43ee-80a9-290206e33cf9',
                externalId: undefined,
                name: 'Cell Style',
                required: true,
                type: 'multiple_choice',
                options: {
                    standard_cell_1: {
                        name: 'Standard Cell 1',
                        id: '0baad004-e042-4518-97cc-4d09c76c8c07',
                        codename: 'standard_cell_1',
                        externalId: undefined
                    },
                    standard_cell_2: {
                        name: 'Standard Cell 2',
                        id: '7d56c4e9-9ba9-41e3-9878-2ce90bf965f0',
                        codename: 'standard_cell_2',
                        externalId: undefined
                    }
                }
            },

            /**
             * Column Span (number)
             */
            column_span: {
                codename: 'column_span',
                id: 'd2f8d1c6-4fa5-4561-a484-77625382c98c',
                externalId: undefined,
                name: 'Column Span',
                required: true,
                type: 'number'
            },

            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '49864b62-f938-4550-a42c-972d02e8634b',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Row Span (number)
             */
            row_span: {
                codename: 'row_span',
                id: '5898bb89-af29-4984-83b1-bc53cf58873e',
                externalId: undefined,
                name: 'Row Span',
                required: true,
                type: 'number'
            },

            /**
             * Text Alignment (multiple_choice)
             */
            text_alignment: {
                codename: 'text_alignment',
                id: 'd85a7040-6c70-4108-b46f-1752c955daa7',
                externalId: undefined,
                name: 'Text Alignment',
                required: true,
                type: 'multiple_choice',
                options: {
                    left: {
                        name: 'Left',
                        id: 'd10e774c-fb3d-46f3-b6a1-3f45964223db',
                        codename: 'left',
                        externalId: undefined
                    },
                    center: {
                        name: 'Center',
                        id: '408da439-ad57-411d-b723-381eaf3b0464',
                        codename: 'center',
                        externalId: undefined
                    },
                    right: {
                        name: 'Right',
                        id: 'e593081e-b5c1-4826-b946-14b15bf2cef9',
                        codename: 'right',
                        externalId: undefined
                    },
                    justify: {
                        name: 'Justify',
                        id: '865fe8bc-df3e-46f3-a88a-669d42bb8ef8',
                        codename: 'justify',
                        externalId: undefined
                    }
                }
            }
        }
    },

    /**
     *    Table Row
     */
    table_row: {
        codename: 'table_row',
        id: '87a77dd7-30d5-4640-a01b-0c6f9251ee48',
        externalId: undefined,
        name: '🔠 Table Row',
        elements: {
            /**
             * Cells (modular_content)
             */
            cells: {
                codename: 'cells',
                id: 'aee61446-52f3-4e89-bb68-a7236b42de5a',
                externalId: undefined,
                name: 'Cells',
                required: true,
                type: 'modular_content'
            },

            /**
             * Is Header (multiple_choice)
             */
            is_header: {
                codename: 'is_header',
                id: 'a654216b-ce67-47c0-ac19-34d7091eefd0',
                externalId: undefined,
                name: 'Is Header',
                required: true,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '57c9b9b2-efe3-40f5-b22d-cfbbb1131693',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: 'cb8b5e52-918e-4740-a534-9acd8548e18d',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            }
        }
    },

    /**
     *    Accordion List
     */
    accordion_list: {
        codename: 'accordion_list',
        id: '18e3dd51-5b07-4a3f-b9d3-573e5d160d57',
        externalId: undefined,
        name: '🧩 Accordion List',
        elements: {
            /**
             * Accordion Items (modular_content)
             */
            accordion_items: {
                codename: 'accordion_items',
                id: '36e9e64b-3b53-448d-871f-b1be2f51cf9f',
                externalId: undefined,
                name: 'Accordion Items',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '474a19f2-c102-45de-ab31-b104e4f2d44e',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Award List
     */
    award_list: {
        codename: 'award_list',
        id: '3beb7db9-e3a7-4222-928d-820917d696d7',
        externalId: undefined,
        name: '🧩 Award List',
        elements: {
            /**
             * Awards (modular_content)
             */
            awards: {
                codename: 'awards',
                id: 'cec90549-6852-4f81-9fb9-2e6e3cf59443',
                externalId: undefined,
                name: 'Awards',
                required: false,
                type: 'modular_content'
            },

            /**
             * Menu Title (text)
             */
            table_of_content_links__menu_title: {
                codename: 'table_of_content_links__menu_title',
                id: '86bb1d2d-72d7-40d1-9b6e-10913dae14b2',
                externalId: undefined,
                name: 'Menu Title',
                required: false,
                type: 'text',

                snippetCodename: 'table_of_content_links'
            },

            /**
             * Table of Contents (multiple_choice)
             */
            table_of_content_links__table_of_contents: {
                codename: 'table_of_content_links__table_of_contents',
                id: 'bef705b9-f448-41de-a0f0-c25f023b6254',
                externalId: undefined,
                name: 'Table of Contents',
                required: true,
                type: 'multiple_choice',
                options: {
                    show: {
                        name: 'Show',
                        id: '3497565f-481d-4d19-94b7-7d68137a4346',
                        codename: 'show',
                        externalId: undefined
                    },
                    hide: {
                        name: 'Hide',
                        id: '22143528-4936-4868-9a64-ecdc6209f309',
                        codename: 'hide',
                        externalId: undefined
                    }
                },
                snippetCodename: 'table_of_content_links'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '5716a5b9-af15-42df-9e60-27a46fc06678',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Banner CTA
     */
    banner_cta: {
        codename: 'banner_cta',
        id: '07bb6caf-2a80-498e-8f9c-7f677375e0ec',
        externalId: undefined,
        name: '🧩 Banner CTA',
        elements: {
            /**
             * Call to Action (modular_content)
             */
            call_to_action: {
                codename: 'call_to_action',
                id: 'b8498622-6213-4dcf-8210-503cf86e4b3f',
                externalId: undefined,
                name: 'Call to Action',
                required: false,
                type: 'modular_content'
            },

            /**
             * Sub Title (rich_text)
             */
            sub_title: {
                codename: 'sub_title',
                id: '21e813d3-8a33-421c-b56e-a1580632eaef',
                externalId: undefined,
                name: 'Sub Title',
                required: false,
                type: 'rich_text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'cfb6cb15-7fac-4f36-bd5d-b5f104e5a842',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Capability List
     */
    capability_list: {
        codename: 'capability_list',
        id: '03b3fbb4-df0b-49e3-a47c-dd37de88a2c2',
        externalId: undefined,
        name: '🧩 Capability List',
        elements: {
            /**
             * Capabilities (modular_content)
             */
            capabilities: {
                codename: 'capabilities',
                id: '09538072-8d14-49fd-bfe5-922d4445612d',
                externalId: undefined,
                name: 'Capabilities',
                required: false,
                type: 'modular_content'
            },

            /**
             * Menu Title (text)
             */
            table_of_content_links__menu_title: {
                codename: 'table_of_content_links__menu_title',
                id: '86bb1d2d-72d7-40d1-9b6e-10913dae14b2',
                externalId: undefined,
                name: 'Menu Title',
                required: false,
                type: 'text',

                snippetCodename: 'table_of_content_links'
            },

            /**
             * Table of Contents (multiple_choice)
             */
            table_of_content_links__table_of_contents: {
                codename: 'table_of_content_links__table_of_contents',
                id: 'bef705b9-f448-41de-a0f0-c25f023b6254',
                externalId: undefined,
                name: 'Table of Contents',
                required: true,
                type: 'multiple_choice',
                options: {
                    show: {
                        name: 'Show',
                        id: '3497565f-481d-4d19-94b7-7d68137a4346',
                        codename: 'show',
                        externalId: undefined
                    },
                    hide: {
                        name: 'Hide',
                        id: '22143528-4936-4868-9a64-ecdc6209f309',
                        codename: 'hide',
                        externalId: undefined
                    }
                },
                snippetCodename: 'table_of_content_links'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '31e31b4b-1d4b-4264-824a-22f0ad024d47',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Card List
     */
    card_list: {
        codename: 'card_list',
        id: 'a90b73f0-4fa4-4a5f-891f-1037a7f10281',
        externalId: undefined,
        name: '🧩 Card List',
        elements: {
            /**
             * Cards (modular_content)
             */
            cards: {
                codename: 'cards',
                id: 'abfb5713-959d-4f11-8969-166fb0c4cbba',
                externalId: undefined,
                name: 'Cards',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'a59d9608-a3be-4ab6-b304-af34ea444bc6',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Client List
     */
    client_list: {
        codename: 'client_list',
        id: '5816ebf7-f3bd-4471-9c56-42e4ebd216d6',
        externalId: undefined,
        name: '🧩 Client List',
        elements: {
            /**
             * Clients (modular_content)
             */
            clients: {
                codename: 'clients',
                id: '00afc1cc-4852-493a-9e32-8e5873c1c152',
                externalId: undefined,
                name: 'Clients',
                required: false,
                type: 'modular_content'
            },

            /**
             * Menu Title (text)
             */
            table_of_content_links__menu_title: {
                codename: 'table_of_content_links__menu_title',
                id: '86bb1d2d-72d7-40d1-9b6e-10913dae14b2',
                externalId: undefined,
                name: 'Menu Title',
                required: false,
                type: 'text',

                snippetCodename: 'table_of_content_links'
            },

            /**
             * Table of Contents (multiple_choice)
             */
            table_of_content_links__table_of_contents: {
                codename: 'table_of_content_links__table_of_contents',
                id: 'bef705b9-f448-41de-a0f0-c25f023b6254',
                externalId: undefined,
                name: 'Table of Contents',
                required: true,
                type: 'multiple_choice',
                options: {
                    show: {
                        name: 'Show',
                        id: '3497565f-481d-4d19-94b7-7d68137a4346',
                        codename: 'show',
                        externalId: undefined
                    },
                    hide: {
                        name: 'Hide',
                        id: '22143528-4936-4868-9a64-ecdc6209f309',
                        codename: 'hide',
                        externalId: undefined
                    }
                },
                snippetCodename: 'table_of_content_links'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '4c2e4e45-3454-44f5-b4a0-68ecc53734fc',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Contact Card
     */
    contact_card: {
        codename: 'contact_card',
        id: '14a4aafc-0f2a-443c-b11e-23a00451871f',
        externalId: undefined,
        name: '🧩 Contact Card',
        elements: {
            /**
             * Address (rich_text)
             */
            address: {
                codename: 'address',
                id: 'd7e1fe35-b2d8-41b5-80c4-b17a226fadc7',
                externalId: undefined,
                name: 'Address',
                required: false,
                type: 'rich_text'
            },

            /**
             * Email (rich_text)
             */
            email: {
                codename: 'email',
                id: '8737ffd7-e858-4d69-8af4-fa6338cdd76a',
                externalId: undefined,
                name: 'Email',
                required: false,
                type: 'rich_text'
            },

            /**
             * Name (text)
             */
            name: {
                codename: 'name',
                id: 'b1d20b67-4b13-4291-98d1-c9109929143a',
                externalId: undefined,
                name: 'Name',
                required: false,
                type: 'text'
            },

            /**
             * Phone Number (rich_text)
             */
            phone_number: {
                codename: 'phone_number',
                id: 'a6218496-24dc-4ff0-8c38-3ad20aa2351e',
                externalId: undefined,
                name: 'Phone Number',
                required: false,
                type: 'rich_text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '212656c3-e972-4205-884e-c4294ea35f11',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Content Block
     */
    content_block: {
        codename: 'content_block',
        id: '8f83489c-a5ac-4fca-bdc5-be486ee00b5a',
        externalId: undefined,
        name: '🧩 Content Block',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: 'b6390330-eb84-4316-a365-5b772598f302',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Menu Title (text)
             */
            table_of_content_links__menu_title: {
                codename: 'table_of_content_links__menu_title',
                id: '86bb1d2d-72d7-40d1-9b6e-10913dae14b2',
                externalId: undefined,
                name: 'Menu Title',
                required: false,
                type: 'text',

                snippetCodename: 'table_of_content_links'
            },

            /**
             * Read More Character Count (number)
             */
            read_more_character_count: {
                codename: 'read_more_character_count',
                id: '5995b468-f67d-4cd9-9da8-f6265046a40d',
                externalId: undefined,
                name: 'Read More Character Count',
                required: false,
                type: 'number'
            },

            /**
             * Table of Contents (multiple_choice)
             */
            table_of_content_links__table_of_contents: {
                codename: 'table_of_content_links__table_of_contents',
                id: 'bef705b9-f448-41de-a0f0-c25f023b6254',
                externalId: undefined,
                name: 'Table of Contents',
                required: true,
                type: 'multiple_choice',
                options: {
                    show: {
                        name: 'Show',
                        id: '3497565f-481d-4d19-94b7-7d68137a4346',
                        codename: 'show',
                        externalId: undefined
                    },
                    hide: {
                        name: 'Hide',
                        id: '22143528-4936-4868-9a64-ecdc6209f309',
                        codename: 'hide',
                        externalId: undefined
                    }
                },
                snippetCodename: 'table_of_content_links'
            },

            /**
             * Text Alignment (multiple_choice)
             */
            text_alignment: {
                codename: 'text_alignment',
                id: 'f892c1c7-29ed-4c22-beb8-d8843d047d7a',
                externalId: undefined,
                name: 'Text Alignment',
                required: false,
                type: 'multiple_choice',
                options: {
                    two_column: {
                        name: 'Two Column',
                        id: '20810820-62e8-4149-9001-9feeb29cb805',
                        codename: 'two_column',
                        externalId: undefined
                    },
                    centered: {
                        name: 'Left aligned',
                        id: '86e92cca-5014-4885-90f1-b9ce0f76c380',
                        codename: 'centered',
                        externalId: undefined
                    }
                }
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '57555d08-7ebd-4e0d-b5f0-e31324ea4895',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Featured Content Block
     */
    featured_content_block: {
        codename: 'featured_content_block',
        id: 'eee7cfad-899e-43d3-bbaa-65b9ffee1dc9',
        externalId: undefined,
        name: '🧩 Featured Content Block',
        elements: {
            /**
             * Featured Content (modular_content)
             */
            featured_content: {
                codename: 'featured_content',
                id: 'cffca422-457c-4059-b671-d09a43b63b4a',
                externalId: undefined,
                name: 'Featured Content',
                required: true,
                type: 'modular_content'
            },

            /**
             * Menu Title (text)
             */
            table_of_content_links__menu_title: {
                codename: 'table_of_content_links__menu_title',
                id: '86bb1d2d-72d7-40d1-9b6e-10913dae14b2',
                externalId: undefined,
                name: 'Menu Title',
                required: false,
                type: 'text',

                snippetCodename: 'table_of_content_links'
            },

            /**
             * Subtitle (text)
             */
            subtitle: {
                codename: 'subtitle',
                id: '25b9212b-0a45-4b01-a7b5-80e3084a72e1',
                externalId: undefined,
                name: 'Subtitle',
                required: false,
                type: 'text'
            },

            /**
             * Table of Contents (multiple_choice)
             */
            table_of_content_links__table_of_contents: {
                codename: 'table_of_content_links__table_of_contents',
                id: 'bef705b9-f448-41de-a0f0-c25f023b6254',
                externalId: undefined,
                name: 'Table of Contents',
                required: true,
                type: 'multiple_choice',
                options: {
                    show: {
                        name: 'Show',
                        id: '3497565f-481d-4d19-94b7-7d68137a4346',
                        codename: 'show',
                        externalId: undefined
                    },
                    hide: {
                        name: 'Hide',
                        id: '22143528-4936-4868-9a64-ecdc6209f309',
                        codename: 'hide',
                        externalId: undefined
                    }
                },
                snippetCodename: 'table_of_content_links'
            },

            /**
             * Text Alignment (multiple_choice)
             */
            text_alignment: {
                codename: 'text_alignment',
                id: 'c10a90ec-ef68-4fea-a74a-98e0264bbf5d',
                externalId: undefined,
                name: 'Text Alignment',
                required: true,
                type: 'multiple_choice',
                options: {
                    two_column: {
                        name: 'Two Column',
                        id: '17e69274-27c2-4a98-a368-538057aa1755',
                        codename: 'two_column',
                        externalId: undefined
                    },
                    centered: {
                        name: 'Centered',
                        id: 'e0067e30-5ea7-42e6-b064-b88d1641cb04',
                        codename: 'centered',
                        externalId: undefined
                    }
                }
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '4446ba23-3858-48a0-bc16-d8b92bc11db2',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Home Hero
     */
    home_hero: {
        codename: 'home_hero',
        id: '1286546d-8446-4ac2-98c0-4934dd157d4c',
        externalId: undefined,
        name: '🧩 Home Hero',
        elements: {
            /**
             * Hero Slides (modular_content)
             */
            hero_slides: {
                codename: 'hero_slides',
                id: '6e64cef8-110a-4747-82c6-ac4682d6754b',
                externalId: undefined,
                name: 'Hero Slides',
                required: true,
                type: 'modular_content'
            }
        }
    },

    /**
     *    iFrame
     */
    _iframe: {
        codename: '_iframe',
        id: 'e24fc42a-a729-4423-9a55-a8781d286e1a',
        externalId: undefined,
        name: '🧩 iFrame',
        elements: {
            /**
             * Embed Code (text)
             */
            embed_code: {
                codename: 'embed_code',
                id: 'e84b6fbd-441a-4af7-b405-9a343f9410ab',
                externalId: undefined,
                name: 'Embed Code',
                required: false,
                type: 'text'
            },

            /**
             * Subtitle (text)
             */
            subtitle: {
                codename: 'subtitle',
                id: 'e2856fe6-0915-452c-ab2f-1732f3db483d',
                externalId: undefined,
                name: 'Subtitle',
                required: false,
                type: 'text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '4d2c0f19-c439-4496-9f2e-e2f4db964fc6',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Image Block
     */
    _image_block: {
        codename: '_image_block',
        id: 'c4bf1e81-e533-44b3-94c3-d649278c3b80',
        externalId: undefined,
        name: '🧩 Image Block',
        elements: {
            /**
             * Alt Text (text)
             */
            alt_text: {
                codename: 'alt_text',
                id: '2eeef786-3abd-4903-951c-f4bd1f5f1beb',
                externalId: undefined,
                name: 'Alt Text',
                required: false,
                type: 'text'
            },

            /**
             * Caption (rich_text)
             */
            caption: {
                codename: 'caption',
                id: 'b78d2a54-b74c-43d4-a66c-42f72aa5e497',
                externalId: undefined,
                name: 'Caption',
                required: false,
                type: 'rich_text'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: '01c7d4da-d100-40ff-a472-6d3400939e87',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Size (multiple_choice)
             */
            size: {
                codename: 'size',
                id: '7a0c5363-eb24-48b1-9902-40383a9f5d1c',
                externalId: undefined,
                name: 'Size',
                required: true,
                type: 'multiple_choice',
                options: {
                    full_width: {
                        name: 'Full Width',
                        id: 'a163d8c2-8522-492d-935b-fa56023f7910',
                        codename: 'full_width',
                        externalId: undefined
                    },
                    actual_size: {
                        name: 'Actual Size',
                        id: '7993ab46-3546-46e9-8e15-71009e8fb04b',
                        codename: 'actual_size',
                        externalId: undefined
                    },
                    specific_size: {
                        name: 'Specific Size',
                        id: '05773071-3031-49d1-80eb-bffe4117be88',
                        codename: 'specific_size',
                        externalId: undefined
                    }
                }
            },

            /**
             * Specified Height (multiple_choice)
             *
             * Height relative to its parent when 'Specific Size' is selected.
             */
            specified_height: {
                codename: 'specified_height',
                id: '84e501e6-5c00-4410-b147-8aa49e318649',
                externalId: undefined,
                name: 'Specified Height',
                required: false,
                type: 'multiple_choice',
                options: {
                    n25_: {
                        name: '25 ',
                        id: '02fee523-35fc-47da-8959-085edcd72f74',
                        codename: 'n25_',
                        externalId: undefined
                    },
                    n50_: {
                        name: '50 ',
                        id: 'b305ead9-8288-4160-9d81-60395d963b8a',
                        codename: 'n50_',
                        externalId: undefined
                    },
                    n75_: {
                        name: '75 ',
                        id: '78790c46-b5f4-44b5-821d-dc744ccf1b50',
                        codename: 'n75_',
                        externalId: undefined
                    },
                    n100_: {
                        name: '100 ',
                        id: '9d5742d8-5e3a-4bae-ade4-62d896d12723',
                        codename: 'n100_',
                        externalId: undefined
                    }
                }
            },

            /**
             * Specified Size Fit (multiple_choice)
             *
             * Ignored on 'Full Width' and 'Actual Size’ options.
             */
            specified_size_fit: {
                codename: 'specified_size_fit',
                id: '0f8ac669-f197-4c51-84fa-46ba9d587df5',
                externalId: undefined,
                name: 'Specified Size Fit',
                required: false,
                type: 'multiple_choice',
                options: {
                    cover: {
                        name: 'Cover',
                        id: 'dca6da03-6114-4a64-9fc0-322dadca9772',
                        codename: 'cover',
                        externalId: undefined
                    },
                    contain: {
                        name: 'Contain',
                        id: '21dc954d-5579-494b-9dce-06ea4bcbce74',
                        codename: 'contain',
                        externalId: undefined
                    },
                    fill: {
                        name: 'Fill',
                        id: '18b6522d-8fc5-40a0-8ea9-b3a174f511fe',
                        codename: 'fill',
                        externalId: undefined
                    }
                }
            },

            /**
             * Specified Width (multiple_choice)
             *
             * Width relative to its parent when 'Specific Size' is selected.
             */
            specified_width: {
                codename: 'specified_width',
                id: '7d0459cc-f674-488a-aa3e-1606a8e644e2',
                externalId: undefined,
                name: 'Specified Width',
                required: false,
                type: 'multiple_choice',
                options: {
                    n25_: {
                        name: '25 ',
                        id: '752eb7a8-7b96-40b4-a47b-b6c731815eb3',
                        codename: 'n25_',
                        externalId: undefined
                    },
                    n50_: {
                        name: '50 ',
                        id: '93cf542a-6c59-4b61-85ac-6b68c287c57b',
                        codename: 'n50_',
                        externalId: undefined
                    },
                    n75_: {
                        name: '75 ',
                        id: '11d51268-2a25-4bcd-b137-32f961d299a2',
                        codename: 'n75_',
                        externalId: undefined
                    },
                    n100_: {
                        name: '100 ',
                        id: 'fbd295e9-5cd2-4059-8c0e-ec9271c568cd',
                        codename: 'n100_',
                        externalId: undefined
                    }
                }
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '5609b40d-d2c0-45ec-9de9-1db484b70dc3',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Insight List
     */
    insight_list_page: {
        codename: 'insight_list_page',
        id: 'd1acc915-ce7d-4ed8-9080-e40b55308b9d',
        externalId: undefined,
        name: '🧩 Insight List',
        elements: {
            /**
             * Capability (taxonomy)
             */
            related_capability: {
                codename: 'related_capability',
                id: '467cda4d-2a23-4f12-aa11-4101ca8a0cc7',
                externalId: undefined,
                name: 'Capability',
                required: false,
                type: 'taxonomy'
            },

            /**
             * Featured Insight Items (modular_content)
             */
            featured_insight_items: {
                codename: 'featured_insight_items',
                id: '5d221cd5-50a8-411f-8b05-6e7efc47af01',
                externalId: undefined,
                name: 'Featured Insight Items',
                required: false,
                type: 'modular_content'
            },

            /**
             * Layout (multiple_choice)
             */
            layout: {
                codename: 'layout',
                id: '67e013aa-71f6-46b8-b715-4f35b4c452d5',
                externalId: undefined,
                name: 'Layout',
                required: false,
                type: 'multiple_choice',
                options: {
                    preview: {
                        name: 'Preview',
                        id: '0720ee63-5014-4294-a211-bb2ce3a601cf',
                        codename: 'preview',
                        externalId: undefined
                    },
                    full_list: {
                        name: 'Full List',
                        id: 'd48b0dc9-a6c4-4d82-9ae7-87590be49071',
                        codename: 'full_list',
                        externalId: undefined
                    }
                }
            },

            /**
             * Menu Title (text)
             */
            table_of_content_links__menu_title: {
                codename: 'table_of_content_links__menu_title',
                id: '86bb1d2d-72d7-40d1-9b6e-10913dae14b2',
                externalId: undefined,
                name: 'Menu Title',
                required: false,
                type: 'text',

                snippetCodename: 'table_of_content_links'
            },

            /**
             * Table of Contents (multiple_choice)
             */
            table_of_content_links__table_of_contents: {
                codename: 'table_of_content_links__table_of_contents',
                id: 'bef705b9-f448-41de-a0f0-c25f023b6254',
                externalId: undefined,
                name: 'Table of Contents',
                required: true,
                type: 'multiple_choice',
                options: {
                    show: {
                        name: 'Show',
                        id: '3497565f-481d-4d19-94b7-7d68137a4346',
                        codename: 'show',
                        externalId: undefined
                    },
                    hide: {
                        name: 'Hide',
                        id: '22143528-4936-4868-9a64-ecdc6209f309',
                        codename: 'hide',
                        externalId: undefined
                    }
                },
                snippetCodename: 'table_of_content_links'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'bca2cb36-3ec7-4311-b3d1-80133ba130ee',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Interior Hero
     */
    interior_hero: {
        codename: 'interior_hero',
        id: 'e8cdee17-88d6-406e-8840-cee64d6cf15e',
        externalId: undefined,
        name: '🧩 Interior Hero',
        elements: {
            /**
             * Asset (asset)
             *
             * Recommended Image size - at least 1440px wide x 400px tall
             */
            asset: {
                codename: 'asset',
                id: '1bfd1a03-43b5-43a8-8ab7-bc51daa9845a',
                externalId: undefined,
                name: 'Asset',
                required: false,
                type: 'asset'
            },

            /**
             * Content (text)
             *
             * Subtitle text/short description.
             */
            content: {
                codename: 'content',
                id: 'f63d4232-dd05-422a-8861-190e9597a20c',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'text'
            },

            /**
             * CTA (modular_content)
             *
             * Optional CTA if you would like to include a button on the hero.
             */
            cta: {
                codename: 'cta',
                id: '289ec6be-a298-43d8-8a0a-e11dca028577',
                externalId: undefined,
                name: 'CTA',
                required: false,
                type: 'modular_content'
            },

            /**
             * Hero Slides (modular_content)
             */
            hero_slides: {
                codename: 'hero_slides',
                id: '5c382f3c-7940-4478-9d84-7fbed1152aa5',
                externalId: undefined,
                name: 'Hero Slides',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             *
             * Primary title on the Hero card with h1 style.
             */
            title: {
                codename: 'title',
                id: 'da519683-fb4f-4549-8bb8-7875c9ef0c03',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     *    Location List
     */
    location_list: {
        codename: 'location_list',
        id: '573d328c-7e88-46d9-b34b-1a63c4c3e747',
        externalId: undefined,
        name: '🧩 Location List',
        elements: {
            /**
             * Display Contact (multiple_choice)
             */
            display_contact: {
                codename: 'display_contact',
                id: '3b08a8e4-9fbe-4e38-92ce-f737096cccc9',
                externalId: undefined,
                name: 'Display Contact',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '66db6d5f-8a73-4e47-8969-aaf1992b58fb',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '0c01da33-823e-4d72-8675-8bbb13b5519a',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            },

            /**
             * Layout (multiple_choice)
             */
            layout: {
                codename: 'layout',
                id: 'e85ae493-c78c-41f7-9950-95bb9e554e40',
                externalId: undefined,
                name: 'Layout',
                required: true,
                type: 'multiple_choice',
                options: {
                    preview: {
                        name: 'Preview',
                        id: '31d17f9f-9e96-4fb8-ba17-7db5f222c697',
                        codename: 'preview',
                        externalId: undefined
                    },
                    full_list: {
                        name: 'Full List',
                        id: '69cd918f-e1fb-4e34-a5d1-e07c1332ad85',
                        codename: 'full_list',
                        externalId: undefined
                    }
                }
            },

            /**
             * Menu Title (text)
             */
            table_of_content_links__menu_title: {
                codename: 'table_of_content_links__menu_title',
                id: '86bb1d2d-72d7-40d1-9b6e-10913dae14b2',
                externalId: undefined,
                name: 'Menu Title',
                required: false,
                type: 'text',

                snippetCodename: 'table_of_content_links'
            },

            /**
             * Show Search (multiple_choice)
             */
            show_search: {
                codename: 'show_search',
                id: '7ff88479-b67b-4388-9377-0ce9b7fcef94',
                externalId: undefined,
                name: 'Show Search',
                required: true,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: 'a02c4191-1476-4435-8308-884131f10e3a',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: 'd29c01fe-6d6f-4c9e-8e6b-fd7ade84c16b',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            },

            /**
             * Table of Contents (multiple_choice)
             */
            table_of_content_links__table_of_contents: {
                codename: 'table_of_content_links__table_of_contents',
                id: 'bef705b9-f448-41de-a0f0-c25f023b6254',
                externalId: undefined,
                name: 'Table of Contents',
                required: true,
                type: 'multiple_choice',
                options: {
                    show: {
                        name: 'Show',
                        id: '3497565f-481d-4d19-94b7-7d68137a4346',
                        codename: 'show',
                        externalId: undefined
                    },
                    hide: {
                        name: 'Hide',
                        id: '22143528-4936-4868-9a64-ecdc6209f309',
                        codename: 'hide',
                        externalId: undefined
                    }
                },
                snippetCodename: 'table_of_content_links'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'cc90f59c-5b54-462c-8b4d-69afb5487701',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Management List
     */
    _management_list: {
        codename: '_management_list',
        id: '579d2b0a-8edc-4d56-9d94-fb72b975603f',
        externalId: undefined,
        name: '🧩 Management List',
        elements: {
            /**
             * Display Contact (multiple_choice)
             */
            display_contact: {
                codename: 'display_contact',
                id: '496587ee-61a0-4942-9fe4-f513822e3826',
                externalId: undefined,
                name: 'Display Contact',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: 'dd4d6319-7b33-47ca-a703-4a1d412886d4',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '5394d42b-01af-44ad-8366-4afe082a5cb0',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            },

            /**
             * Layout (multiple_choice)
             */
            layout: {
                codename: 'layout',
                id: '8282d3c2-c2df-40de-ac18-b52e7d20ac16',
                externalId: undefined,
                name: 'Layout',
                required: true,
                type: 'multiple_choice',
                options: {
                    full_list: {
                        name: 'Full List',
                        id: '675f1ddb-1ed7-4b7d-ac68-cc3d86fc2878',
                        codename: 'full_list',
                        externalId: undefined
                    }
                }
            },

            /**
             * Management (modular_content)
             */
            management: {
                codename: 'management',
                id: 'b4152832-7000-4864-8e81-baa8dddda827',
                externalId: undefined,
                name: 'Management',
                required: true,
                type: 'modular_content'
            },

            /**
             * Menu Title (text)
             */
            table_of_content_links__menu_title: {
                codename: 'table_of_content_links__menu_title',
                id: '86bb1d2d-72d7-40d1-9b6e-10913dae14b2',
                externalId: undefined,
                name: 'Menu Title',
                required: false,
                type: 'text',

                snippetCodename: 'table_of_content_links'
            },

            /**
             * Show as Manatt Ventures Team (multiple_choice)
             */
            show_as_manatt_ventures_team: {
                codename: 'show_as_manatt_ventures_team',
                id: '72cef5c7-e14b-4105-abda-47aacb797389',
                externalId: undefined,
                name: 'Show as Manatt Ventures Team',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '22772b01-5bee-45a7-b987-83b100ce6588',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '1a561447-af41-4d10-a0ab-6fba4e29d820',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            },

            /**
             * Table of Contents (multiple_choice)
             */
            table_of_content_links__table_of_contents: {
                codename: 'table_of_content_links__table_of_contents',
                id: 'bef705b9-f448-41de-a0f0-c25f023b6254',
                externalId: undefined,
                name: 'Table of Contents',
                required: true,
                type: 'multiple_choice',
                options: {
                    show: {
                        name: 'Show',
                        id: '3497565f-481d-4d19-94b7-7d68137a4346',
                        codename: 'show',
                        externalId: undefined
                    },
                    hide: {
                        name: 'Hide',
                        id: '22143528-4936-4868-9a64-ecdc6209f309',
                        codename: 'hide',
                        externalId: undefined
                    }
                },
                snippetCodename: 'table_of_content_links'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '0afb9f87-e400-4ee7-9952-17fe74dc2190',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Music Streaming Calculator
     */
    music_streaming_calculator: {
        codename: 'music_streaming_calculator',
        id: '7e00be26-de03-4521-aee3-fff8d3c62d4f',
        externalId: undefined,
        name: '🧩 Music Streaming Calculator',
        elements: {
            /**
             * Music Streaming Parameters (modular_content)
             */
            music_streaming_parameters: {
                codename: 'music_streaming_parameters',
                id: '1ce8e53c-82fc-4d0d-9e2a-9384680fbfca',
                externalId: undefined,
                name: 'Music Streaming Parameters',
                required: false,
                type: 'modular_content'
            },

            /**
             * Show Advanced (multiple_choice)
             */
            show_advanced: {
                codename: 'show_advanced',
                id: '974c0c6a-4d77-487a-a41b-05f59f49339b',
                externalId: undefined,
                name: 'Show Advanced',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: 'a110f053-049a-4d71-8656-c764d21453a1',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '6fe6135b-78f4-4758-b25e-98848c0b03cf',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            }
        }
    },

    /**
     *    Open Positions List
     */
    open_positions_list: {
        codename: 'open_positions_list',
        id: 'e32f8b47-70dd-4c65-8921-1fae5f8652bf',
        externalId: undefined,
        name: '🧩 Open Positions List',
        elements: {
            /**
             * Career Category (taxonomy)
             */
            filters: {
                codename: 'filters',
                id: '7b053366-7cc5-4db3-9b68-e5c8f21f3a25',
                externalId: undefined,
                name: 'Career Category',
                required: false,
                type: 'taxonomy'
            },

            /**
             * Layout (multiple_choice)
             */
            layout: {
                codename: 'layout',
                id: 'fede3151-dfa4-442d-94df-2efc8cba810b',
                externalId: undefined,
                name: 'Layout',
                required: false,
                type: 'multiple_choice',
                options: {
                    preview: {
                        name: 'Preview',
                        id: '111679da-932d-45b5-b3e6-fa7bc3f68399',
                        codename: 'preview',
                        externalId: undefined
                    },
                    full_list: {
                        name: 'Full List',
                        id: 'f64d8f7a-2f86-4acf-a6d5-4653b175777a',
                        codename: 'full_list',
                        externalId: undefined
                    }
                }
            },

            /**
             * Menu Title (text)
             */
            table_of_content_links__menu_title: {
                codename: 'table_of_content_links__menu_title',
                id: '86bb1d2d-72d7-40d1-9b6e-10913dae14b2',
                externalId: undefined,
                name: 'Menu Title',
                required: false,
                type: 'text',

                snippetCodename: 'table_of_content_links'
            },

            /**
             * Table of Contents (multiple_choice)
             */
            table_of_content_links__table_of_contents: {
                codename: 'table_of_content_links__table_of_contents',
                id: 'bef705b9-f448-41de-a0f0-c25f023b6254',
                externalId: undefined,
                name: 'Table of Contents',
                required: true,
                type: 'multiple_choice',
                options: {
                    show: {
                        name: 'Show',
                        id: '3497565f-481d-4d19-94b7-7d68137a4346',
                        codename: 'show',
                        externalId: undefined
                    },
                    hide: {
                        name: 'Hide',
                        id: '22143528-4936-4868-9a64-ecdc6209f309',
                        codename: 'hide',
                        externalId: undefined
                    }
                },
                snippetCodename: 'table_of_content_links'
            },

            /**
             * Title (text)
             */
            title_: {
                codename: 'title_',
                id: '3b29861a-1738-41ee-bd56-288083563dc8',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Page List
     */
    _page_list: {
        codename: '_page_list',
        id: '654d0792-8b33-419b-a0c4-21bf3e06318b',
        externalId: undefined,
        name: '🧩 Page List',
        elements: {}
    },

    /**
     *    People Search Block
     */
    people_search_block: {
        codename: 'people_search_block',
        id: '5f508564-6878-47be-96f4-3b5821ed0f97',
        externalId: undefined,
        name: '🧩 People Search Block',
        elements: {
            /**
             * Desktop Image (asset)
             */
            image: {
                codename: 'image',
                id: '0ee78937-04e8-4a69-92d4-67adb136fc61',
                externalId: undefined,
                name: 'Desktop Image',
                required: true,
                type: 'asset'
            },

            /**
             * Mobile Image (asset)
             */
            mobile_image: {
                codename: 'mobile_image',
                id: '619a6a24-2c55-4490-92e1-4755e6520adf',
                externalId: undefined,
                name: 'Mobile Image',
                required: false,
                type: 'asset'
            },

            /**
             * Search Button Text (text)
             */
            search_button_text: {
                codename: 'search_button_text',
                id: 'a8fbdbb4-2b80-46c1-b70d-95e952229cb2',
                externalId: undefined,
                name: 'Search Button Text',
                required: true,
                type: 'text'
            },

            /**
             * Subtitle (text)
             */
            subtitle: {
                codename: 'subtitle',
                id: '16227493-efda-4894-ac23-0e5632558432',
                externalId: undefined,
                name: 'Subtitle',
                required: true,
                type: 'text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'a8ecec72-e351-429e-9a1b-4489eaaf7149',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            },

            /**
             * View All Text (text)
             */
            view_all_text: {
                codename: 'view_all_text',
                id: 'c2f98fa6-b2c3-46ae-916d-a9dbc07aa4fc',
                externalId: undefined,
                name: 'View All Text',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     *    Quick Fact List
     */
    quick_fact_list: {
        codename: 'quick_fact_list',
        id: '43f43ec6-897b-440e-a246-19afa3e73efa',
        externalId: undefined,
        name: '🧩 Quick Fact List',
        elements: {
            /**
             * Quick Fact (modular_content)
             */
            quick_fact: {
                codename: 'quick_fact',
                id: '0c147cf3-0129-40bd-b4b8-f1ac5cc71610',
                externalId: undefined,
                name: 'Quick Fact',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '23497237-c7c9-47e7-8318-e23363348100',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Reference List
     */
    _reference_list: {
        codename: '_reference_list',
        id: 'e64c3bb6-cb75-43c9-b33d-8f74f6688230',
        externalId: undefined,
        name: '🧩 Reference List',
        elements: {
            /**
             * Default State (multiple_choice)
             */
            default_state: {
                codename: 'default_state',
                id: 'e430355b-8174-4dfe-8bc6-42545831729d',
                externalId: undefined,
                name: 'Default State',
                required: false,
                type: 'multiple_choice',
                options: {
                    expanded: {
                        name: 'Expanded',
                        id: '81489e07-edf4-457f-8bde-90634ebf1cc3',
                        codename: 'expanded',
                        externalId: undefined
                    },
                    collapsed: {
                        name: 'Collapsed',
                        id: 'f26b0db1-6df2-42e9-8354-d32336cc160a',
                        codename: 'collapsed',
                        externalId: undefined
                    }
                }
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '403d7547-9c28-4e3c-ae8b-6f83572ca11a',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Share Icons
     */
    share_icons: {
        codename: 'share_icons',
        id: '604f6761-b716-46fb-9869-e72e8c3f5538',
        externalId: undefined,
        name: '🧩 Share Icons',
        elements: {
            /**
             * Facebook (text)
             */
            facebook: {
                codename: 'facebook',
                id: '6a9c59f2-1ade-4896-a444-689d94b7f2b1',
                externalId: undefined,
                name: 'Facebook',
                required: false,
                type: 'text'
            },

            /**
             * Instagram (text)
             */
            instagram: {
                codename: 'instagram',
                id: 'c2737e49-28d7-4a12-af21-7adcbd6ad5b1',
                externalId: undefined,
                name: 'Instagram',
                required: false,
                type: 'text'
            },

            /**
             * X (text)
             */
            x: {
                codename: 'x',
                id: 'db02499e-1011-45f7-bd0a-17c2cf685f1b',
                externalId: undefined,
                name: 'X',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Split Content Block
     */
    split_content_block: {
        codename: 'split_content_block',
        id: '87a6e267-c94e-4821-b1b7-5e25a0579635',
        externalId: undefined,
        name: '🧩 Split Content Block',
        elements: {
            /**
             * Block Styling (multiple_choice)
             */
            block_styling: {
                codename: 'block_styling',
                id: 'b4fe684a-05ef-46fa-8ab8-3fbe40891e64',
                externalId: undefined,
                name: 'Block Styling',
                required: false,
                type: 'multiple_choice',
                options: {
                    n60_40: {
                        name: '60 40',
                        id: '84b103e0-fe47-4d12-bbe4-fb03b225c811',
                        codename: 'n60_40',
                        externalId: undefined
                    },
                    n40_60: {
                        name: '40 60',
                        id: '85a1dc93-ac69-4deb-b75d-6b0a62a99ad2',
                        codename: 'n40_60',
                        externalId: undefined
                    },
                    n50_50: {
                        name: '50 50',
                        id: 'b91391c4-78a5-4dc7-9efe-24d162b3b323',
                        codename: 'n50_50',
                        externalId: undefined
                    },
                    n70_30: {
                        name: '70 30',
                        id: 'f4ec56ea-54a6-48c4-9ab3-1526ecd36aee',
                        codename: 'n70_30',
                        externalId: undefined
                    },
                    n30_70: {
                        name: '30 70',
                        id: '23083539-8483-437d-922c-4712ccbfc448',
                        codename: 'n30_70',
                        externalId: undefined
                    }
                }
            },

            /**
             * Left Content (rich_text)
             */
            left_content: {
                codename: 'left_content',
                id: '8ecc5729-4a20-4b2c-b754-16c23669cc17',
                externalId: undefined,
                name: 'Left Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Menu Title (text)
             */
            table_of_content_links__menu_title: {
                codename: 'table_of_content_links__menu_title',
                id: '86bb1d2d-72d7-40d1-9b6e-10913dae14b2',
                externalId: undefined,
                name: 'Menu Title',
                required: false,
                type: 'text',

                snippetCodename: 'table_of_content_links'
            },

            /**
             * Mobile Styling (multiple_choice)
             */
            mobile_styling: {
                codename: 'mobile_styling',
                id: '6d5c8f2d-6acd-4c65-b55a-cea09406c0dc',
                externalId: undefined,
                name: 'Mobile Styling',
                required: false,
                type: 'multiple_choice',
                options: {
                    left_content_on_top: {
                        name: 'Left Content on Top',
                        id: '56b471b3-1921-4d20-bc6c-e8f21c38cde5',
                        codename: 'left_content_on_top',
                        externalId: undefined
                    },
                    right_content_on_top: {
                        name: 'Right Content on Top',
                        id: 'c64e1123-6b45-472d-a9e1-0733a35bc112',
                        codename: 'right_content_on_top',
                        externalId: undefined
                    }
                }
            },

            /**
             * Right Content (rich_text)
             */
            right_content: {
                codename: 'right_content',
                id: 'ee98dfda-77fa-4699-b10e-099072f229a1',
                externalId: undefined,
                name: 'Right Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Table of Contents (multiple_choice)
             */
            table_of_content_links__table_of_contents: {
                codename: 'table_of_content_links__table_of_contents',
                id: 'bef705b9-f448-41de-a0f0-c25f023b6254',
                externalId: undefined,
                name: 'Table of Contents',
                required: true,
                type: 'multiple_choice',
                options: {
                    show: {
                        name: 'Show',
                        id: '3497565f-481d-4d19-94b7-7d68137a4346',
                        codename: 'show',
                        externalId: undefined
                    },
                    hide: {
                        name: 'Hide',
                        id: '22143528-4936-4868-9a64-ecdc6209f309',
                        codename: 'hide',
                        externalId: undefined
                    }
                },
                snippetCodename: 'table_of_content_links'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '71a9d7da-d624-472d-b4f2-1c531f1b5782',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Subscribe Button
     */
    subscribe_button: {
        codename: 'subscribe_button',
        id: 'bab1ca83-d859-4322-8893-e190231626af',
        externalId: undefined,
        name: '🧩 Subscribe Button',
        elements: {
            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'c4af3a26-f0b5-43b0-840d-89f82f3f8796',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Tab Content Block
     */
    tab_content_block: {
        codename: 'tab_content_block',
        id: 'ca7876be-ae16-4ee4-9ad6-e7780aefc0aa',
        externalId: undefined,
        name: '🧩 Tab Content Block',
        elements: {
            /**
             * Menu Title (text)
             */
            table_of_content_links__menu_title: {
                codename: 'table_of_content_links__menu_title',
                id: '86bb1d2d-72d7-40d1-9b6e-10913dae14b2',
                externalId: undefined,
                name: 'Menu Title',
                required: false,
                type: 'text',

                snippetCodename: 'table_of_content_links'
            },

            /**
             * Tab Sections (modular_content)
             */
            tab_sections: {
                codename: 'tab_sections',
                id: '632f2340-82de-42b3-afb8-3b60d8871848',
                externalId: undefined,
                name: 'Tab Sections',
                required: false,
                type: 'modular_content'
            },

            /**
             * Table of Contents (multiple_choice)
             */
            table_of_content_links__table_of_contents: {
                codename: 'table_of_content_links__table_of_contents',
                id: 'bef705b9-f448-41de-a0f0-c25f023b6254',
                externalId: undefined,
                name: 'Table of Contents',
                required: true,
                type: 'multiple_choice',
                options: {
                    show: {
                        name: 'Show',
                        id: '3497565f-481d-4d19-94b7-7d68137a4346',
                        codename: 'show',
                        externalId: undefined
                    },
                    hide: {
                        name: 'Hide',
                        id: '22143528-4936-4868-9a64-ecdc6209f309',
                        codename: 'hide',
                        externalId: undefined
                    }
                },
                snippetCodename: 'table_of_content_links'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'a24c518f-0d80-47cd-a667-2f913046f411',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Table
     */
    table: {
        codename: 'table',
        id: '7f02f0d6-4fdb-440f-9cc0-8be7929e9fe9',
        externalId: undefined,
        name: '🧩 Table',
        elements: {
            /**
             * Header Style (multiple_choice)
             */
            header_style: {
                codename: 'header_style',
                id: '16877467-3167-4e08-b4ca-30c5b04f0b7b',
                externalId: undefined,
                name: 'Header Style',
                required: true,
                type: 'multiple_choice',
                options: {
                    standard_header_1: {
                        name: 'Standard Header 1',
                        id: '02dd4202-c560-48db-95b2-4fe47f5f4597',
                        codename: 'standard_header_1',
                        externalId: undefined
                    },
                    standard_header_2: {
                        name: 'Standard Header 2',
                        id: '3c4c22cf-60c6-426f-a4ca-8c2f5d42a621',
                        codename: 'standard_header_2',
                        externalId: undefined
                    },
                    standard_header_3: {
                        name: 'Standard Header 3',
                        id: '6a3e973e-458f-4e67-bec4-31b60c200075',
                        codename: 'standard_header_3',
                        externalId: undefined
                    },
                    standard_header_4: {
                        name: 'Standard Header 4',
                        id: '4c476c29-75a4-42f0-84fd-f30ba91f1d6e',
                        codename: 'standard_header_4',
                        externalId: undefined
                    }
                }
            },

            /**
             * Is Border Visible (multiple_choice)
             */
            is_border_visible: {
                codename: 'is_border_visible',
                id: 'fa0f2db1-58b7-4038-926a-638c83a06f86',
                externalId: undefined,
                name: 'Is Border Visible',
                required: true,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '4ec52e0f-beed-4fb8-b841-5d0788c3d3f8',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: 'afd74247-ea25-4d9e-a058-0ea56cf442af',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            },

            /**
             * Rows (modular_content)
             */
            rows: {
                codename: 'rows',
                id: 'e06d9cd0-334c-454b-843f-4ea1ec29270f',
                externalId: undefined,
                name: 'Rows',
                required: true,
                type: 'modular_content'
            }
        }
    },

    /**
     *    Team List
     */
    team_list: {
        codename: 'team_list',
        id: 'e5725590-a2ea-44da-845d-7196940b3484',
        externalId: undefined,
        name: '🧩 Team List',
        elements: {
            /**
             * Capability (taxonomy)
             */
            related_capability: {
                codename: 'related_capability',
                id: 'd3076e5a-192f-4ba7-b9f8-87de9b2e08a3',
                externalId: undefined,
                name: 'Capability',
                required: false,
                type: 'taxonomy'
            },

            /**
             * Layout (multiple_choice)
             */
            layout: {
                codename: 'layout',
                id: '256d2da5-d90d-4741-8f93-daa564161e24',
                externalId: undefined,
                name: 'Layout',
                required: false,
                type: 'multiple_choice',
                options: {
                    preview: {
                        name: 'Preview',
                        id: '0c9856ca-9b67-4baa-a3f3-25b8c0ddfbf6',
                        codename: 'preview',
                        externalId: undefined
                    },
                    full_list: {
                        name: 'Full List',
                        id: '91d87e12-1d45-4bc4-8b39-93a5dcec12cb',
                        codename: 'full_list',
                        externalId: undefined
                    }
                }
            },

            /**
             * Members (modular_content)
             */
            members: {
                codename: 'members',
                id: '7011f346-51cd-44ca-9c92-41b2c571d3e9',
                externalId: undefined,
                name: 'Members',
                required: false,
                type: 'modular_content'
            },

            /**
             * Menu Title (text)
             */
            table_of_content_links__menu_title: {
                codename: 'table_of_content_links__menu_title',
                id: '86bb1d2d-72d7-40d1-9b6e-10913dae14b2',
                externalId: undefined,
                name: 'Menu Title',
                required: false,
                type: 'text',

                snippetCodename: 'table_of_content_links'
            },

            /**
             * Table of Contents (multiple_choice)
             */
            table_of_content_links__table_of_contents: {
                codename: 'table_of_content_links__table_of_contents',
                id: 'bef705b9-f448-41de-a0f0-c25f023b6254',
                externalId: undefined,
                name: 'Table of Contents',
                required: true,
                type: 'multiple_choice',
                options: {
                    show: {
                        name: 'Show',
                        id: '3497565f-481d-4d19-94b7-7d68137a4346',
                        codename: 'show',
                        externalId: undefined
                    },
                    hide: {
                        name: 'Hide',
                        id: '22143528-4936-4868-9a64-ecdc6209f309',
                        codename: 'hide',
                        externalId: undefined
                    }
                },
                snippetCodename: 'table_of_content_links'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '88b4c9cb-1201-40a0-83fc-849d0c23fa63',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Ventures Insight Search Block
     */
    ventures_insight_search_block: {
        codename: 'ventures_insight_search_block',
        id: 'b1bbe778-fd9b-4fbe-9f64-3374dde38b8f',
        externalId: undefined,
        name: 'Ventures Insight Search Block',
        elements: {}
    },

    /**
     * Web spotlight root
     */
    web_spotlight_root: {
        codename: 'web_spotlight_root',
        id: 'e892e512-bf92-4d03-9124-e4cab084922a',
        externalId: undefined,
        name: 'Web spotlight root',
        elements: {
            /**
             * Banner CTA (modular_content)
             */
            banner_cta: {
                codename: 'banner_cta',
                id: '6a7e3ab2-2351-4e68-8177-b5909a04bf9c',
                externalId: undefined,
                name: 'Banner CTA',
                required: true,
                type: 'modular_content'
            },

            /**
             * Content (modular_content)
             */
            content: {
                codename: 'content',
                id: 'a34ff992-dbef-43e0-ac43-23fe1298bfca',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'modular_content'
            },

            /**
             * Featured Content (modular_content)
             */
            featured_content: {
                codename: 'featured_content',
                id: '0859a93c-563a-447a-9a23-49ace01a1992',
                externalId: undefined,
                name: 'Featured Content',
                required: true,
                type: 'modular_content'
            },

            /**
             * Hero (modular_content)
             */
            hero: {
                codename: 'hero',
                id: '8c07d84f-671e-4819-81ef-94ed94f85133',
                externalId: undefined,
                name: 'Hero',
                required: true,
                type: 'modular_content'
            },

            /**
             * Meta Keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '0ca5c9ec-b34f-48df-933b-792568906e4c',
                externalId: undefined,
                name: 'Meta Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Page URL (custom)
             */
            urls__page_url: {
                codename: 'urls__page_url',
                id: '8d8e9c64-809c-4fb2-af2d-a62f2a368cfc',
                externalId: undefined,
                name: 'Page URL',
                required: false,
                type: 'custom',

                snippetCodename: 'urls'
            },

            /**
             * People Search (modular_content)
             */
            people_search: {
                codename: 'people_search',
                id: '6a8839b9-02fc-463c-9c97-ec04594aea73',
                externalId: undefined,
                name: 'People Search',
                required: true,
                type: 'modular_content'
            },

            /**
             * Subpages (subpages)
             */
            subpages: {
                codename: 'subpages',
                id: '25364df1-5efc-4ce5-942c-3a26a480b2ce',
                externalId: undefined,
                name: 'Subpages',
                required: false,
                type: 'subpages'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '982d339a-8d95-447f-8f6f-8b9a2f1521f7',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: 'feeed5fc-c946-42fa-a388-123469626def',
                externalId: undefined,
                name: 'URL Slug',
                required: true,
                type: 'url_slug'
            }
        }
    }
} as const;
