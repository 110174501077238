import Popover from 'bootstrap/js/dist/popover'
import Tooltip from 'bootstrap/js/dist/tooltip'
import Carousel from 'bootstrap/js/dist/carousel'
import Collapse from 'bootstrap/js/dist/collapse'
import Dropdown from 'bootstrap/js/dist/dropdown'
import Modal from 'bootstrap/js/dist/modal'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      bsPopover: (el: any, options: any) => new Popover(el, options),
      bsTooltip: Tooltip,
      bsCarousel: Carousel,
      bsCollapse: Collapse,
      bsDropdown: Dropdown,
      bsModal: (el: any) => new Modal(el)
    }
  }
})
